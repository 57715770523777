import { Component, OnInit, Input } from '@angular/core';
import { ViewSDKClient } from './view-sdk.service';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css'],
})
export class PdfViewComponent implements OnInit {
  @Input() url: string;
  @Input() filename: string;
  @Input() embedMode: string; // INLINE, SIZED_CONTAINER, LIGHTBOX

  constructor(private viewSDKClient: ViewSDKClient) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadPDF()
  }

  ngOnChanges() {
    this.loadPDF()
  }

  loadPDF() {
    function detectMob() {
      const toMatch = [ /Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    }
    this.viewSDKClient.ready().then(() => {
      this.viewSDKClient.previewFile(
        'pdf-div',
        {
          embedMode: detectMob() ? 'IN_LINE' : this.embedMode, // override with IN_LINE on mobile
        },
        this.url,
        this.filename
      );
    });
  }
}
