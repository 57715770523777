<!-- blog text -->
<div style="display: none;" class="rounded-box blog">
    <h4>What do I do?</h4>
    <p>
        I'm a Customer Success Engineer from Sydney, Australia. I help with the bring-up of measurements and experiments on quantum computers at Quantum Machines. The goal of my work is to seamlessly integrate state-of-the-art quantum control equipment with a variety of different quantum computing platforms and thus push the boundaries of what was previously possible. I have a dual bachelors degrees in Advanced Physics with honours in Quantum Computing, and in Computer Science, majoring in Artificial Intelligence at the University of New South Wales.
    </p>
    <h4>How did I get here?</h4>
    <p>
        I spent my primary school years messing around with computing, design and problem solving, mostly through Microsoft Paint, and later, command prompt, Adobe Photoshop and simple Windows scripts, founding the interest I have in Computer Science today. In later years, I became fascinated with neural networks. Without knowing anything about how they worked, I was captivated by the idea of harnessing the intelligent structures of a human brain for important applications. Even though Physics was never my strongest subject at school, I was also curious about what made the world tick. Physics seems like such an important and foundational science to me because it spans the entire scale of the universe, from quantum mechanics to general relativity. I believe the unsolved problems in physics hold many of the secrets to the universe, and I love to explore the boundary of what we do and don't know about the universe.
    </p>
    <h4>Why Quantum Computing?</h4>
    <p>
        My passion for computer science and physics led me to quantum computing, which has proven to be a deeply interesting and inter-disciplinary field. It combines complexity thoery, control software design and quantum algorithm theory from computer science, control theory, signal processing and hardware design from electrical engineering, and quantum device modelling and design from quantum physics. This makes quantum computing an exciting and dynamic industry. Additionally, there is a global race to prove which quantum computing platform will scale the best, from Silicon, semiconductor qubits which extend the design motivations of classical electronics to more exotic, topological qubits like the Majorana qubit, each with their own advantages and disadvantages. It is extremely exciting to follow the development of quantum computing and witness the evolution of a new technology akin to the birth of classical computing in the 1900's. Some investigations in quantum computing which interest me the most are OrchOR, which postulates that consciousness originates at the quantum level inside neurons, and simulation, which aims to model small-scale interactions in the physical world using constructed quantum circuits for drug design and medicine.
    </p>
    <h4>About this Website</h4>
    <p>
        I designed this website originally as a place to download my CV, but it grew into a passion project to better understand and define myself in a digital space. I wrote it more or less from scratch and currently host it from a spare computer turned web server, so that my only website-specific cost is the domain name.
    </p>
</div>

<!-- art carousel -->
<div class="art-carousel rounded-box">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <!-- <li data-target="#carouselExampleIndicators" data-slide-to="1"></li> -->
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
        <img class="d-block w-100" 
            src="assets/img/about/me-stm.jpg" alt="Me Operating a Scanning Tunnelling Microscope">
        </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" 
        role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" 
        role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    </div>
</div>