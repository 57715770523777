<!-- side bar -->
<div style="display: none;" class="personal-info rounded-box">

    <!-- profile image -->
    <img class="profile-image" src="assets/img/resume/profile.png">
    <div class="profile-text">
        <p>
            <b style="font-size: 16pt">Dean Poulos</b><br>
            Customer Success Engineer/Physicist<br> <i>Quantum Machines</i><br>
        </p>
    </div>

    <!-- social media -->
    <div style="display:flex; justify-content:center; font-size: 14pt">
        <a style="align-content:space-between" href="https://github.com/deanpoulos"
            class="fa fa-github btn-light bg-white"></a>
        &nbsp;&nbsp;&nbsp;
        <a style="align-content:space-between" href="https://www.deviantart.com/kothanos"
            class="fa fa-deviantart btn-light bg-white"></a>
        &nbsp;&nbsp;&nbsp;
        <a style="align-content:space-between" href="https://www.linkedin.com/in/dean-poulos-61903715b/"
            class="fa fa-linkedin btn-light bg-white"></a>
    </div>
    <br>

    <!-- top skills -->
    <div class="skills-table">
        <div>
            <circle-progress [percent]="85" [subtitle]="'C'"></circle-progress>
        </div>
        <div>
            <circle-progress [percent]="100" [subtitle]="'Python'"></circle-progress>
        </div>
        <div>
            <circle-progress [percent]="75" [subtitle]="'Java'"></circle-progress>
        </div>
    </div>

    <!-- other skills -->
    <div class="other-skills">
        <div>
            <span>C#, SystemVerilog</span>
            <img src="assets/img/resume/8-in-10.png">
        </div>
        <div>
            <span>MATLAB, LaTeX</span>
            <img src="assets/img/resume/8-in-10.png">
        </div>
        <div>
            <span>CSS, HTML, JavaScript</span>
            <img src="assets/img/resume/7-in-10.png">
        </div>
        <div>
            <span>Visual Basic</span>
            <img src="assets/img/resume/6-in-10.png">
        </div>
        <div>
            <span>Photoshop, Excel</span>
            <img src="assets/img/resume/8-in-10.png">
        </div>
    </div>
    <br>

    <!-- details -->
    <div style="padding-top:5px;">
        <table style="margin: auto;">
            <tr>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td class="about-title">name</td>
                <td>Dean Terry Poulos</td>
            </tr>
            <tr>
                <td></td>
                <td class="about-title">e-mail</td>
                <td>dean.poulos7@gmail.com</td>
            </tr>
            <tr>
                <td></td>
                <td class="about-title">phone</td>
                <td>(+61) 466 488 088</td>
            </tr>
        </table>
    </div>
    <br>

    <!-- download button -->
    <div style="text-align: center; padding:20px">
        <a href="assets/files/resume.pdf" role="button" class="btn btn-light" download="dean-poulos-resume">
            Download R&eacute;sum&eacute;
        </a>
    </div>

</div>

<!-- content separator -->
<div class="content-div"> </div>

<!-- resume text -->
<div class="rounded-box resume">
    <app-pdf-view 
        url="/assets/files/resume.pdf" 
        filename="dean-poulos-resume.pdf"
        embedMode="SIZED_CONTAINER"
    ></app-pdf-view>
</div>