import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { ResumeComponent } from './resume/resume.component';
import { SoftwareComponent } from './software/software.component';
import { ResearchComponent } from './research/research.component';
import { DigitalArtComponent } from './digital-art/digital-art.component';
import { MusicComponent } from './music/music.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { UnityGameComponent } from './unity-game/unity-game.component';
import { MusicViewerComponent } from './music-viewer/music-viewer.component';
import { PdfViewComponent } from './pdf-view/pdf-view.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AboutComponent,
    ResumeComponent,
    SoftwareComponent,
    ResearchComponent,
    MusicComponent,
    DigitalArtComponent,
    PageNotFoundComponent,
    UnityGameComponent,
    MusicViewerComponent,
    PdfViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    BrowserAnimationsModule,
    NgCircleProgressModule.forRoot({
      "backgroundPadding": 7,
      "radius": 60,
      "space": -2,
      "outerStrokeWidth": 5,
      "outerStrokeColor": "#000a3d",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 2,
      "titleFontSize": "28",
      "subtitleFontSize": "22",
      "unitsFontSize": "18",
      "animateTitle": true,
      "animationDuration": 1300,
      "showUnits": true,
      "clockwise": false,
      "showTitle": true,
      "subtitleColor": "#000000"
    })
  ],
  providers: [],
  bootstrap: [AppComponent]


})
export class AppModule { }
