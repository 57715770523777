<div class="heading rounded-box">
    <h1>Welcome to my Portfolio</h1>
    <p>
    Here I showcase some of my hobby projects, those that I generally work
    on for fun during my uni breaks. My favourite of these projects and
    probably the most fun was a game I made for my girlfriend before she
    left for a long trip which is playable just below. The main themes of my
    hobby projects are creativity; things that aren't necessarily practical
    but are either fun or innovative. I have a lot of fun making art and
    designing things hence my dedication to projects like the unity game
    and my dital art (see 'Digital Art' on the navigation bar).
    </p>

    <!-- transition bar -->
    <div>
    <nav aria-label="Portfolio Navigation" >
        <ul class="pagination justify-content-center" style="cursor: pointer;">
        <li class="page-item"><a class="page-link btn" id="btn-left" value="-1">Previous</a></li>
        <li id="l1" class="page-item">
            <a class="page-link" (click)="fadeTo('1')">1</a></li>
        <li id="l2" class="page-item">
            <a class="page-link" (click)="fadeTo('3')">2</a></li>
        <li id="l3" class="page-item">
            <a class="page-link" (click)="fadeTo('3')">3</a></li>
        <li id="l4" class="page-item">
            <a class="page-link" (click)="fadeTo('4')">4</a></li>
        <li id="l5" class="page-item">
            <a class="page-link" (click)="fadeTo('5')">5</a></li>
        <li class="page-item"><a class="page-link btn" id="btn-right" value="1">Next</a></li>
        </ul>
    </nav>
    </div>
</div>

<!-- portfolio pages -->
<div class="portfolio-wrapper">
    <div id="1" class="portfolio rounded-box" >
    <div class="alert alert-warning" role="alert">
        Note: I haven't updated my software portfolio in a while...
        Check out my <a href="https://github.com/deanpoulos">github</a> or my 
        <a href="assets/files/resume.pdf">resume</a> for more recent software projects!
    </div>

    <h2>RPG Game</h2>
    I made this game using Unity3D in C#. I designed the scenes to be a
    satirical home. In this game you play as Mushi the bunny. I
    enjoyed investing time into making abstract systems for things like
    dialog, battle and movement so that the game was easily extendable to
    more characters, environments and battles. 
    <br> <br> <br>
    <app-unity-game url="assets/game/unity-game.html"></app-unity-game>
    <br> <br> <br>
    <h3>Development</h3>
    I designed the game scenes using free sprites and standard methods of 2D
    map building, i.e. downloading large images called sprite-sheets which
    are collections of small sprites spliced together, slicing them in the
    game engine then painting them onto many layers of grid canvas. You can
    see the underlying grid base to the sprites in the following screenshot
    of the engine.
    <br><br>
    <img src="assets/img/software/engine-ui-1.png" style="width: 80%; display: block;
    margin-left: auto; margin-right: auto; text-align: center;">
    <br><br>
    The most fun part of designing the game was creating the battle
    animations and walking animations. This was done by creating time-sequences 
    of object positions, scales, rotations and sprite images. This created
    the effect of the desired motion. Walking was easy to animate as most
    character spritesheets are supplied with many images featuring different
    combinations of character orientation and leg positions. The harder part
    of the animation was the battle scenes which have a larger range of
    desired motions. For example, the bunny 'Schnuggle' move used 5+
    different sprite images and required animation of position, scale and
    rotation to achieve the desired effect. The screenshot below shows the
    animation panel at the bottom where keyframes are set and the game panel
    shows the execution of the animation.
    <br><br>
    <img src="assets/img/software/engine-ui-2.png" style="width: 80%; display: block;
    margin-left: auto; margin-right: auto; text-align: center;">
    <br><br>
    The hardest part of game mechanics was the battle scene. The battle scene
    was abstracted as a complex state machine with states such as dialog, user
    input, animation, damage calculation, dialog, enemy animation, etc...
    Each component of which is non-trivial in itself. Below is a code snippet
    of the function signatures for the ExecuteMove superclass:
    <br><br>
    <img src="assets/img/software/engine-ui-4.png" class="portfolio-long-image">
    <img src="assets/img/software/engine-ui-5.png" class="portfolio-long-image">
    <br><br>
    The function implementations which are not shown in the code snippet
    interface with Unity's animation engine by triggering specified
    animations. These triggers cause the character object to transition
    between two states in it's own animation state machine. Here is what the
    main character's animation state machine looks like, shown in the left
    panel, as well as the timing/transitions between states shown on the
    right.
    <br><br>
    <img src="assets/img/software/engine-ui-3.png" style="width: 80%; display: block;
    margin-left: auto; margin-right: auto; text-align: center;">
    <br><br>
    </div>
    <div id="2" class="portfolio rounded-box">
    <h2>Python Currency Arbitrage Bot</h2>
    <h3>Arbitrage</h3>
    Arbitrage is the purchase and sale of an asset in order to profit from a
    difference in the asset's price between markets. It is a trade that
    profits by exploiting the price differences of identical or similar
    financial instruments on different markets or in different forms.
    Arbitrage exists as a result of market inefficiencies and would
    therefore not exist if all markets were perfectly efficient.
    <br> <br>
    <h3>Project</h3>
    This project uses a command-line interface to a python backend which
    connects to two pre-loaded cryptocurrency exchange APIs. After choice of
    exchange pair, current fees and asset trading prices are fetched and a
    difference is computed. Considering all current fees, the profit ratio
    is computed and returned every ten seconds. A screenshot of the UI is
    given below with some sample data.
    <br> <br>
    <img src="assets/img/software/sm-ui-1.jpg" class="portfolio-image">
    <br><br>
    <h3>Code</h3>
    The code is quite complex and hard to maintain because it requires
    multiple different API calls for the same values. The way data is stored
    differs between exchange APIs and so the data must be adapted so that it
    is uniform. I coded this in python before I had much experience in
    object-oriented design, and so going forward it's definitely something I
    could apply to improve the code. Here is some of the code used for
    calculating the value of a trade cycle:
    <br><br>
    <img src="assets/img/software/sm-ui-2.png" class="portfolio-image">
    </div>
    <div id="3" class="portfolio rounded-box">
    <h2>Python Trading Recommendation Web-Scanner</h2>
    <h3>Background</h3>
        Another of my python projects targeted toward investing is my trading
        recommendation web-scanner. Many investing websites track the
        technical indicators which are tools for analysing the trajectory fo
        an asset live. These are just quantifiers which are accepted as good
        predictors of an asset's price action. The default recommendation
        takes an average of all the technical indicator recommendations to 
        give an overview. Here is an example of <a
        href="tradingview.com">Trading View</a>'s technical analysis
        charts for BTC/USD:
        <br><br>
        <img src="assets/img/software/oc-ui-1.png" class="portfolio-image">
        <br><br>
    <h3>Project</h3>
        My program uses a Python backend with Selenium to scrape the Trading
        View website's trading recommendation and log an output of
        recommendation againts the price action of an asset. This raw data was
        then used to correlate recommendation versus actual portfolio growth
        by simulating various strategies against different recommendation time
        intervals. The program fetches live price and recommendation data for
        dynamically for whichever desired cryptocurrency asset. 
        Here is an example of the program output:
        <br><br>
        <img src="assets/img/software/oc-ui-2.png" class="portfolio-long-image">
        <img src="assets/img/software/oc-ui-3.png" class="portfolio-long-image">
        <br><br>
    <h3>Code</h3>
        The backend uses a headless browser to navigate to the desired trading
        view asset's recommendation page to activate the javascript which runs 
        the widget. After sufficient loadng time, the driver fetches the raw
        innerHTML data and searches it for the overview recommendation. After
        that, it fetches the asset's price data and logs them side-by-side.
        This repeats every thirty seconds and produces a log. Here is a
        snippet of the code:
        <img src="assets/img/software/oc-ui-4.png" class="portfolio-image">
    </div>
    <div id="4" class="portfolio rounded-box">
    <h2>Java FXML Dungeon Game</h2>
    <h3>Project Aims</h3>
    This project was undertaken as part of course work during COMP2511
    studied at UNSW. The aims of the project were:
    <ul>
        <li>Appreciate issues in user interface design</li>
        <li>Learn practical aspects of graphical user interface programming</li>
        <li>Learn more about the Java class libraries</li>
        <li>Learn the application of design patterns.</li>
    </ul>
    <h3>Overview</h3>
    You have received a request from a client for an application for the
    playing of dungeon-style puzzles. With a partner from your lab class, you
    will follow an agile development process to design and implement a desktop
    Java application that satisfies the requirements of the client (see
    below). The final piece of software you deliver is expected to be of
    professional quality, user-friendly, and demonstrate the knowledge and
    skills you have acquired in this course.
    <br><br>
    <h3>Requirements</h3>
    The simulated client requirements are a game with components as follows:
    <br><br>
    <img src="assets/img/software/jfx-2.png" class="portfolio-image" style="width:80%;">
    <br><br>
    <h3>Design</h3>
    The project began with a conceptual class diagram outlining all the
    required components and patterns. Some of the object-oriented design
    patterns used include the observer pattern, composite pattern and state
    pattern. Here is the conceptual class diagram:
    <img src="assets/img/software/jfx-1.png" class="portfolio-image">
    <br><br>
    <h3>Final Product</h3>
    After long collaboration with my lab partner, we achieved a well-rounded
    final product with various extensions such as an fxml level-builder, UI
    with a level picker, a help screen and pause menu. Here is a
    demonstration of the game:
    <br><br>
    <div style="display: block; margin: auto;">
    <video width="80%" controls>
        <source src="assets/img/software/jfx-3.mp4" type="video/mp4" style="display: block;
        width: 60%; margin: 0 auto;">
        Your browser does not support the video tag.
    </video>
    </div>
    </div>
    <div id="5" class="portfolio rounded-box">
    <h2>Flask Web Application</h2>
    <div class="alert alert-warning" role="alert">
        Note: I haven't updated my software portfolio in a while...
        Check out my <a href="https://github.com/deanpoulos">github</a> or my 
        <a href="assets/files/resume.pdf">resume</a> for more recent software projects!
    </div>
    </div>
</div>