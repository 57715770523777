import { Component, OnInit } from '@angular/core';
import { TimelineLite, Power2, Power3, TweenLite } from 'gsap';
import Draggable from 'gsap/Draggable';
import gsap from 'gsap';
import * as $ from 'jquery';

@Component({
  selector: 'app-music-viewer',
  templateUrl: './music-viewer.component.html',
  styleUrls: ['./music-viewer.component.css'],
})
export class MusicViewerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    var svg:SVGSVGElement = document.querySelector('#svg');
    
    var proxy = document.createElement('div');
    var viewport = document.querySelector('#viewport');
    
    var point = svg.createSVGPoint();
    var startClient = svg.createSVGPoint();
    var startGlobal = svg.createSVGPoint();

    var viewBox = svg.viewBox.baseVal;
    var cachedViewBox = { x: viewBox.x, y: viewBox.y, width: viewBox.width, height: viewBox.height };

    var zoom = { animation: null, scaleFactor: 1.4, duration: 0.25, ease: Power3.easeOut };

    var resetAnimation = new TimelineLite();

    gsap.registerPlugin(Draggable, TweenLite);
    
    var pannable = Draggable.create(proxy, {
      throwResistance: 3000,
      trigger: svg,
      throwProps: true,
      onPress: selectDraggable,
      onDrag: updateViewBox,
      onThrowUpdate: updateViewBoxThrow,
    })

    window.addEventListener('wheel', onWheel);
    svg.addEventListener('mouseleave', (event) => {jQuery('html').css('overflow', 'scroll')});
    svg.addEventListener('mouseenter', (event) => {jQuery('html').css('overflow', 'hidden')});

    function onWheel(event) {
      // prevent scrolling
      if($("#svg:hover").length == 0) { return true; } 
      var normalized;
      var delta = event.wheelDelta;

      normalized = delta % 120 == 0 ? delta / 120 : delta / 12;
      var scaleDelta = normalized > 0 ? 1 / zoom.scaleFactor : zoom.scaleFactor;

      point.x = event.clientX;
      point.y = event.clientY;

      var startPoint = point.matrixTransform(svg.getScreenCTM().inverse());

      var fromVars = { ease: zoom.ease, x: viewBox.x, y: viewBox.y, width: viewBox.width, height: viewBox.height, duration: zoom.duration  };

      if (viewBox.width <= cachedViewBox.width  && viewBox.height <= cachedViewBox.height ) {
        viewBox.width = Math.min(cachedViewBox.width, viewBox.width * scaleDelta);
        viewBox.height = Math.min(cachedViewBox.height, viewBox.height * scaleDelta);
      }
      if ((viewBox.width < cachedViewBox.width  && viewBox.height < cachedViewBox.height )) {
        viewBox.x = viewBox.x - (startPoint.x - viewBox.x) * (scaleDelta - 1);
        viewBox.y = viewBox.y - (startPoint.y - viewBox.y) * (scaleDelta - 1);
      }

      zoom.animation = gsap.from(viewBox, fromVars);
    
      return false;
    }

    function selectDraggable(event) {
      if (resetAnimation.isActive()) {
        resetAnimation.kill();
      }

      startClient.x = this.pointerX;
      startClient.y = this.pointerY;
      startGlobal = startClient.matrixTransform(svg.getScreenCTM().inverse());

      gsap.set(proxy, {x: this.pointerX, y: this.pointerY });

      pannable[0].enable().update()
    }

    function updateViewBoxThrow() {
      alert("thrown!");
      updateViewBox()
    }

    function updateViewBox() {
      if (zoom.animation && zoom.animation.isActive()) {
        return;
      }

      point.x = this.x;
      point.y = this.y;

      var moveGlobal = point.matrixTransform(svg.getScreenCTM().inverse());

      viewBox.x = viewBox.x - (moveGlobal.x - startGlobal.x);
      viewBox.y = viewBox.y - (moveGlobal.y - startGlobal.y);

      viewBox.x = Math.max(viewBox.x, -2000)
      viewBox.x = Math.min(viewBox.x, 1000)
      viewBox.y = Math.max(viewBox.y, -2000)
      viewBox.y = Math.min(viewBox.y, 1000)
    }
  }
}
