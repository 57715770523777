<div id="svg-container">

  <!-- replace id with svg -->
  <!-- remove edges -->
  <!-- replace nodes id with viewport -->

  <svg id="svg" contentScriptType="text/ecmascript" width="3781.1243" xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify" contentStyleType="text/css" viewBox="-1000 -1000 1260 1137"
    height="3413.0696" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g id="viewport">
      <text font-size="18" x="1330.8381" y="1312.2156" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_guitar">
        classical guitar
      </text>
      <text font-size="17" x="1260.2566" y="1337.7913" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_guitarra_argentina">
        guitarra argentina
      </text>
      <text font-size="17" x="1146.1953" y="1297.5824" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_guitarra_clasica">
        guitarra clasica
      </text>
      <text font-size="21" x="565.4667" y="1104.7908" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bandoneon">
        bandoneon
      </text>
      <text font-size="18" x="565.41504" y="1055.7156" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_latin_classical">
        latin classical
      </text>
      <text font-size="17" x="325.9296" y="1243.1156" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nuevo_tango">
        nuevo tango
      </text>
      <text font-size="17" x="428.5271" y="1214.0026" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_tango">
        tango
      </text>
      <text font-size="17" x="1126.0569" y="957.78723" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_japanese_classical_performance">
        japanese classical performance
      </text>
      <text font-size="17" x="1135.3303" y="1018.3574" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_guitar">
        japanese guitar
      </text>
      <text font-size="17" x="-860.8868" y="1073.9491" fill="#cc3d8b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_folk_rock">
        ukrainian folk rock
      </text>
      <text font-size="17" x="-934.80194" y="1091.3337" fill="#cc3d8b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_pop">
        ukrainian pop
      </text>
      <text font-size="17" x="-758.58923" y="1127.2375" fill="#cc3d8b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_rock">
        ukrainian rock
      </text>
      <text font-size="20" x="-1299.7916" y="-488.43716" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_jazz">
        turkish jazz
      </text>
      <text font-size="18" x="-1287.6387" y="-471.3079" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_rock">
        turkish rock
      </text>
      <text font-size="17" x="-1376.5542" y="-447.81113" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_turkish_singer-songwriter">
        turkish singer-songwriter
      </text>
      <text font-size="17" x="-1311.2576" y="-429.74405" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_modern_jazz">
        turkish modern jazz
      </text>
      <text font-size="16" x="-1489.1238" y="-383.44464" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_arabesk">
        arabesk
      </text>
      <text font-size="17" x="-1456.4436" y="-398.59985" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_turkish_pop">
        classic turkish pop
      </text>
      <text font-size="16" x="-1495.056" y="-416.8554" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_psych">
        turkish psych
      </text>
      <text font-size="17" x="-1312.4164" y="-511.89377" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_pop">
        turkish pop
      </text>
      <text font-size="17" x="-1178.4138" y="-333.7707" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_turkish_classical">
        turkish classical
      </text>
      <text font-size="17" x="-1171.9176" y="-468.14685" fill="#00aa00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_turkish_instrumental">
        turkish instrumental
      </text>
      <text font-size="28" x="-302.1544" y="214.1354" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_pop">
        indie pop
      </text>
      <text font-size="17" x="-436.35938" y="378.52097" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_emo">
        lo-fi emo
      </text>
      <text font-size="17" x="-353.3163" y="453.80676" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_indie">
        lo-fi indie
      </text>
      <text font-size="21" x="810.28326" y="-128.7404" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_chill">
        lo-fi chill
      </text>
      <text font-size="21" x="546.17377" y="-91.140076" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sad_lo-fi">
        sad lo-fi
      </text>
      <text font-size="20" x="660.53546" y="-165.63257" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sad_rap">
        sad rap
      </text>
      <text font-size="18" x="411.15396" y="-514.30334" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vapor_soul">
        vapor soul
      </text>
      <text font-size="20" x="390.46786" y="-493.19373" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vapor_twitch">
        vapor twitch
      </text>
      <text font-size="16" x="834.29504" y="-702.65173" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_focus_beats">
        focus beats
      </text>
      <text font-size="23" x="835.8975" y="-506.3404" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_beats">
        lo-fi beats
      </text>
      <text font-size="25" x="878.43945" y="-618.8773" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chillhop">
        chillhop
      </text>
      <text font-size="16" x="950.8544" y="-547.53436" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_future_funk">
        future funk
      </text>
      <text font-size="17" x="856.0912" y="1110.7708" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_j-ambient">
        j-ambient
      </text>
      <text font-size="16" x="729.9329" y="1257.7544" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_piano">
        japanese piano
      </text>
      <text font-size="23" x="420.3264" y="-93.66383" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_emo_rap">
        emo rap
      </text>
      <text font-size="19" x="623.8354" y="-663.2054" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_chillhop">
        japanese chillhop
      </text>
      <text font-size="16" x="618.42487" y="-575.6647" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rochester_mn_indie">
        rochester mn indie
      </text>
      <text font-size="19" x="775.345" y="-467.08853" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_rap">
        lo-fi rap
      </text>
      <text font-size="17" x="714.80945" y="-634.635" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lo-fi_house">
        lo-fi house
      </text>
      <text font-size="16" x="-281.9878" y="435.99597" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ambient_folk">
        ambient folk
      </text>
      <text font-size="19" x="219.01526" y="538.68243" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_indie_pop">
        french indie pop
      </text>
      <text font-size="17" x="136.48413" y="515.00525" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_indietronica">
        french indietronica
      </text>
      <text font-size="16" x="-360.93695" y="-1544.9094" fill="#b05c00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_portland_indie">
        portland indie
      </text>
      <text font-size="16" x="-378.26984" y="-1562.2366" fill="#b05c00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_shimmer_pop">
        shimmer pop
      </text>
      <text font-size="19" x="-415.24078" y="101.90117" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chill_r&amp;b">
        chill r&amp;b
      </text>
      <text font-size="19" x="-909.60724" y="-145.69025" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_korean_r&amp;b">
        korean r&amp;b
      </text>
      <text font-size="18" x="-995.8151" y="55.56754" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_k-pop">
        k-pop
      </text>
      <text font-size="19" x="1394.9424" y="-105.12827" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_c-pop">
        c-pop
      </text>
      <text font-size="19" x="1354.3055" y="-30.119516" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_audiophile">
        chinese audiophile
      </text>
      <text font-size="23" x="1225.5038" y="-1.5885859" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_indie">
        chinese indie
      </text>
      <text font-size="19" x="1293.7689" y="-198.12451" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_jazz">
        chinese jazz
      </text>
      <text font-size="22" x="1148.2981" y="-83.09671" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_mandopop">
        mandopop
      </text>
      <text font-size="21" x="1311.0195" y="-84.63711" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_taiwan_singer-songwriter">
        taiwan singer-songwriter
      </text>
      <text font-size="18" x="-231.32512" y="187.17014" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bedroom_pop">
        bedroom pop
      </text>
      <text font-size="17" x="-324.20078" y="266.07214" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bubblegrunge">
        bubblegrunge
      </text>
      <text font-size="26" x="85.186005" y="408.7049" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chanson">
        chanson
      </text>
      <text font-size="19" x="298.97458" y="559.33215" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_folk_pop">
        french folk pop
      </text>
      <text font-size="23" x="171.72672" y="567.1835" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_pop">
        french pop
      </text>
      <text font-size="19" x="194.98315" y="368.13904" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_rock">
        french rock
      </text>
      <text font-size="20" x="66.215744" y="531.47" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nantes_indie">
        nantes indie
      </text>
      <text font-size="21" x="180.12163" y="608.724" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_nouvelle_chanson_francaise">
        nouvelle chanson francaise
      </text>
      <text font-size="25" x="-872.6248" y="390.2132" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_adult_standards">
        adult standards
      </text>
      <text font-size="24" x="-1056.4329" y="752.64996" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz">
        jazz
      </text>
      <text font-size="23" x="-766.81055" y="657.5496" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lounge">
        lounge
      </text>
      <text font-size="22" x="-1019.42755" y="552.04254" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_swing">
        swing
      </text>
      <text font-size="28" x="-881.2666" y="432.25238" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vocal_jazz">
        vocal jazz
      </text>
      <text font-size="16" x="-1384.3129" y="768.14" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canzone_napoletana">
        canzone napoletana
      </text>
      <text font-size="18" x="-1378.9083" y="825.43945" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_italian_pop">
        classic italian pop
      </text>
      <text font-size="17" x="-1359.8009" y="1078.1536" fill="#2e76d2"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_american_contemporary_classical">
        american contemporary classical
      </text>
      <text font-size="17" x="-1198.8185" y="1150.5764" fill="#2e76d2"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_microtonal">
        microtonal
      </text>
      <text font-size="17" x="-1194.7822" y="1066.0391" fill="#2e76d2"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_xenharmonic">
        xenharmonic
      </text>
      <text font-size="27" x="963.4808" y="-1356.5347" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical">
        classical
      </text>
      <text font-size="18" x="1096.8007" y="-1383.5215" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_early_avant_garde">
        early avant garde
      </text>
      <text font-size="19" x="1026.0494" y="-1321.5739" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_early_modern_classical">
        early modern classical
      </text>
      <text font-size="19" x="953.04944" y="-1274.0476" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_impressionism">
        impressionism
      </text>
      <text font-size="23" x="1104.4264" y="-1433.1431" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_post-romantic_era">
        post-romantic era
      </text>
      <text font-size="16" x="1059.3224" y="976.0653" fill="#00a8ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_orchestra">
        japanese orchestra
      </text>
      <text font-size="19" x="-1616.4088" y="185.74692" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hollywood">
        hollywood
      </text>
      <text font-size="16" x="-1464.3525" y="-83.05689" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nyc_pop">
        nyc pop
      </text>
      <text font-size="20" x="-237.99553" y="37.597076" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_la_indie">
        la indie
      </text>
      <text font-size="19" x="-806.2775" y="460.31274" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_easy_listening">
        easy listening
      </text>
      <text font-size="20" x="-772.02734" y="584.0752" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_torch_song">
        torch song
      </text>
      <text font-size="19" x="-998.64014" y="487.138" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_big_band">
        big band
      </text>
      <text font-size="17" x="-904.7431" y="541.911" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_british_dance_band">
        british dance band
      </text>
      <text font-size="16" x="-826.18677" y="370.23944" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop_quebecois">
        pop quebecois
      </text>
      <text font-size="17" x="-877.73517" y="479.4048" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_blues">
        jazz blues
      </text>
      <text font-size="17" x="-1089.4978" y="283.07434" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bebop">
        bebop
      </text>
      <text font-size="17" x="-1206.8081" y="266.36" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_fusion">
        jazz fusion
      </text>
      <text font-size="18" x="-1123.8859" y="506.1437" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_guitar">
        jazz guitar
      </text>
      <text font-size="18" x="-954.91565" y="454.68564" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_contemporary_vocal_jazz">
        contemporary vocal jazz
      </text>
      <text font-size="23" x="588.1204" y="281.1452" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_piano">
        classical piano
      </text>
      <text font-size="17" x="706.7291" y="258.06628" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_french_classical_piano">
        french classical piano
      </text>
      <text font-size="16" x="1072.2275" y="1223.1719" fill="#00a683"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_cabaret">
        cabaret
      </text>
      <text font-size="16" x="931.22876" y="1089.4695" fill="#00a683"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vintage_hollywood">
        vintage hollywood
      </text>
      <text font-size="21" x="-974.7041" y="664.8509" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_contemporary_jazz">
        contemporary jazz
      </text>
      <text font-size="23" x="238.51169" y="416.75677" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_jazz">
        french jazz
      </text>
      <text font-size="17" x="283.45087" y="654.7782" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_jazz">
        italian jazz
      </text>
      <text font-size="17" x="106.41635" y="646.5927" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_indie_folk">
        french indie folk
      </text>
      <text font-size="17" x="328.6658" y="479.34653" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ye_ye">
        ye ye
      </text>
      <text font-size="17" x="1687.6392" y="-403.34644" fill="#d25318"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bossa_nova">
        bossa nova
      </text>
      <text font-size="17" x="1726.2485" y="-419.25934" fill="#d25318"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_mpb">
        mpb
      </text>
      <text font-size="17" x="1675.1116" y="-427.61346" fill="#d25318"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_samba">
        samba
      </text>
      <text font-size="17" x="1714.7876" y="-472.2674" fill="#d25318"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_violao">
        violao
      </text>
      <text font-size="22" x="230.38332" y="1040.8105" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_indie">
        canadian indie
      </text>
      <text font-size="22" x="-120.36917" y="96.53247" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_pop">
        canadian pop
      </text>
      <text font-size="18" x="-361.3313" y="245.93008" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chamber_pop">
        chamber pop
      </text>
      <text font-size="33" x="-329.93802" y="1018.58887" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_singer-songwriter">
        singer-songwriter
      </text>
      <text font-size="18" x="-1328.0187" y="889.07477" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_canzone_d&apos;autore">
        canzone d&apos;autore
      </text>
      <text font-size="17" x="-1309.4015" y="807.1214" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canzone_genovese">
        canzone genovese
      </text>
      <text font-size="17" x="-1313.7236" y="850.7953" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_adult_pop">
        italian adult pop
      </text>
      <text font-size="16" x="-1461.9481" y="804.7894" fill="#ff10bd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_alternative">
        italian alternative
      </text>
      <text font-size="19" x="-406.92072" y="-293.22778" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_british_soul">
        british soul
      </text>
      <text font-size="48" x="-221.49185" y="-156.80318" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop">
        pop
      </text>
      <text font-size="21" x="-227.77934" y="-220.94319" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop_soul">
        pop soul
      </text>
      <text font-size="21" x="-146.0499" y="-143.25087" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_uk_pop">
        uk pop
      </text>
      <text font-size="45" x="-371.46765" y="-158.4639" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dance_pop">
        dance pop
      </text>
      <text font-size="19" x="-733.0152" y="-318.73236" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_disco">
        disco
      </text>
      <text font-size="19" x="-703.0266" y="-231.57062" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_disco_house">
        disco house
      </text>
      <text font-size="19" x="-667.4859" y="-303.28445" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_philly_soul">
        philly soul
      </text>
      <text font-size="19" x="-709.8441" y="-282.3108" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_post-disco">
        post-disco
      </text>
      <text font-size="19" x="-733.2712" y="-262.17224" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_quiet_storm">
        quiet storm
      </text>
      <text font-size="23" x="-771.6529" y="-298.9137" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_soul">
        soul
      </text>
      <text font-size="29" x="-171.11043" y="-252.72362" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_art_pop">
        art pop
      </text>
      <text font-size="19" x="-527.1686" y="409.78534" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bay_area_indie">
        bay area indie
      </text>
      <text font-size="19" x="-519.725" y="362.208" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_experimental_indie_rock">
        experimental indie rock
      </text>
      <text font-size="21" x="-527.3523" y="319.09634" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_experimental_pop">
        experimental pop
      </text>
      <text font-size="26" x="-324.35684" y="618.85486" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_experimental_rock">
        experimental rock
      </text>
      <text font-size="19" x="-596.80255" y="341.23758" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_garage_psych">
        garage psych
      </text>
      <text font-size="19" x="-526.8619" y="287.95862" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_noise_pop">
        noise pop
      </text>
      <text font-size="20" x="8.56177" y="-614.50696" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alternative_dance">
        alternative dance
      </text>
      <text font-size="17" x="192.2206" y="-979.9114" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bergen_indie">
        bergen indie
      </text>
      <text font-size="32" x="15.157957" y="-1231.4507" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_electronica">
        electronica
      </text>
      <text font-size="16" x="-1686.0468" y="601.07446" fill="#546f9e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_afroswing">
        afroswing
      </text>
      <text font-size="16" x="-1831.9366" y="606.5204" fill="#546f9e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_uk_hip_hop">
        uk hip hop
      </text>
      <text font-size="36" x="-286.91327" y="-122.98279" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_edm">
        edm
      </text>
      <text font-size="26" x="-10.203378" y="-333.24847" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop_dance">
        pop dance
      </text>
      <text font-size="22" x="-203.96127" y="-341.03735" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_post-teen_pop">
        post-teen pop
      </text>
      <text font-size="21" x="-124.92117" y="-509.8954" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_tropical_house">
        tropical house
      </text>
      <text font-size="16" x="438.4046" y="-75.50673" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_brooklyn_drill">
        brooklyn drill
      </text>
      <text font-size="21" x="-16.667904" y="-160.83264" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nyc_rap">
        nyc rap
      </text>
      <text font-size="30" x="-311.62656" y="-268.7354" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alternative_r&amp;b">
        alternative r&amp;b
      </text>
      <text font-size="19" x="-308.64087" y="-15.19737" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_r&amp;b">
        indie r&amp;b
      </text>
      <text font-size="16" x="1515.1398" y="-1327.6521" fill="#b25a39"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_j-pixie">
        j-pixie
      </text>
      <text font-size="16" x="1498.4034" y="-1310.6528" fill="#b25a39"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_electropop">
        japanese electropop
      </text>
      <text font-size="26" x="17.857176" y="62.550842" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_melodic_rap">
        melodic rap
      </text>
      <text font-size="24" x="124.235504" y="-7.525099" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_viral_rap">
        viral rap
      </text>
      <text font-size="17" x="-287.55548" y="-416.80353" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_australian_hip_hop">
        australian hip hop
      </text>
      <text font-size="16" x="1469.5095" y="868.78143" fill="#2f8b00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_j-acoustic">
        j-acoustic
      </text>
      <text font-size="16" x="1452.7112" y="885.7907" fill="#2f8b00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_vocal_jazz">
        japanese vocal jazz
      </text>
      <text font-size="19" x="-735.35376" y="410.9736" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vocal_harmony_group">
        vocal harmony group
      </text>
      <text font-size="28" x="-71.80947" y="-985.38403" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_soul">
        indie soul
      </text>
      <text font-size="17" x="-342.9689" y="55.99079" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_uk_contemporary_r&amp;b">
        uk contemporary r&amp;b
      </text>
      <text font-size="16" x="184.85696" y="138.82486" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_comic">
        comic
      </text>
      <text font-size="29" x="228.31459" y="-189.74994" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_conscious_hip_hop">
        conscious hip hop
      </text>
      <text font-size="22" x="0.42996955" y="-187.64389" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_atl_hip_hop">
        atl hip hop
      </text>
      <text font-size="23" x="-44.50947" y="-136.76328" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dirty_south_rap">
        dirty south rap
      </text>
      <text font-size="28" x="116.45704" y="-303.41684" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_gangster_rap">
        gangster rap
      </text>
      <text font-size="48" x="221.45152" y="-231.2295" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hip_hop">
        hip hop
      </text>
      <text font-size="34" x="87.82059" y="-275.15857" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop_rap">
        pop rap
      </text>
      <text font-size="47" x="224.58623" y="-279.975" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rap">
        rap
      </text>
      <text font-size="35" x="-277.25906" y="-37.565258" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_southern_hip_hop">
        southern hip hop
      </text>
      <text font-size="36" x="9.553987" y="-237.58173" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_trap">
        trap
      </text>
      <text font-size="17" x="-200.90959" y="74.42978" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_canadian_contemporary_r&amp;b">
        canadian contemporary r&amp;b
      </text>
      <text font-size="27" x="-205.30624" y="-387.14523" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_electropop">
        electropop
      </text>
      <text font-size="29" x="212.02063" y="-94.81067" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_escape_room">
        escape room
      </text>
      <text font-size="16" x="-537.7388" y="-330.85333" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_colombian_pop">
        colombian pop
      </text>
      <text font-size="20" x="-364.16287" y="85.219666" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bedroom_soul">
        bedroom soul
      </text>
      <text font-size="32" x="-243.44856" y="-294.8374" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_r&amp;b">
        r&amp;b
      </text>
      <text font-size="16" x="-551.3288" y="-88.43404" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_norwegian_pop">
        norwegian pop
      </text>
      <text font-size="28" x="-286.7616" y="859.0381" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alternative_rock">
        alternative rock
      </text>
      <text font-size="33" x="-389.83224" y="891.9473" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_art_rock">
        art rock
      </text>
      <text font-size="21" x="-309.62878" y="727.0044" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_experimental">
        experimental
      </text>
      <text font-size="21" x="-499.0337" y="600.0121" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_krautrock">
        krautrock
      </text>
      <text font-size="21" x="-326.30164" y="575.2221" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_neo-psychedelic">
        neo-psychedelic
      </text>
      <text font-size="21" x="-208.78395" y="531.067" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_post-punk">
        post-punk
      </text>
      <text font-size="28" x="-484.6081" y="985.758" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_psychedelic_rock">
        psychedelic rock
      </text>
      <text font-size="21" x="-228.9575" y="697.0194" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_space_rock">
        space rock
      </text>
      <text font-size="21" x="-262.37338" y="-198.29832" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alt_z">
        alt z
      </text>
      <text font-size="28" x="-443.0496" y="-238.30003" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_urban_contemporary">
        urban contemporary
      </text>
      <text font-size="17" x="-456.28018" y="206.48682" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ann_arbor_indie">
        ann arbor indie
      </text>
      <text font-size="37" x="397.9835" y="-123.73654" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_underground_hip_hop">
        underground hip hop
      </text>
      <text font-size="18" x="-34.3548" y="35.745644" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dmv_rap">
        dmv rap
      </text>
      <text font-size="29" x="-36.295605" y="-23.36665" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alternative_hip_hop">
        alternative hip hop
      </text>
      <text font-size="17" x="416.13477" y="-168.05298" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_canadian_modern_jazz">
        canadian modern jazz
      </text>
      <text font-size="17" x="-135.39038" y="47.85723" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_modern_indie_pop">
        modern indie pop
      </text>
      <text font-size="17" x="248.33788" y="-157.82645" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_north_carolina_hip_hop">
        north carolina hip hop
      </text>
      <text font-size="17" x="-269.15234" y="347.81198" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_surf">
        indie surf
      </text>
      <text font-size="17" x="-1333.3102" y="414.40094" fill="#008eb5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sped_up">
        sped up
      </text>
      <text font-size="16" x="-1349.268" y="429.18817" fill="#008eb5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_weirdcore">
        weirdcore
      </text>
      <text font-size="17" x="1118.0402" y="838.1084" fill="#00aee5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chill_breakcore">
        chill breakcore
      </text>
      <text font-size="17" x="1019.705" y="843.84937" fill="#00aee5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_glitchbreak">
        glitchbreak
      </text>
      <text font-size="23" x="597.23157" y="-63.489086" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_aesthetic_rap">
        aesthetic rap
      </text>
      <text font-size="18" x="601.7423" y="-148.5211" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_meme_rap">
        meme rap
      </text>
      <text font-size="16" x="-328.97433" y="-915.0802" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_electronic_rock">
        electronic rock
      </text>
      <text font-size="19" x="-199.96992" y="-895.26874" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_rave">
        new rave
      </text>
      <text font-size="17" x="-617.85394" y="141.05023" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_afro_r&amp;b">
        afro r&amp;b
      </text>
      <text font-size="18" x="-785.2062" y="-18.58012" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_ghanaian_alternative">
        ghanaian alternative
      </text>
      <text font-size="17" x="-771.44385" y="88.290695" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ghanaian_pop">
        ghanaian pop
      </text>
      <text font-size="16" x="-761.4889" y="234.19997" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nigerian_pop">
        nigerian pop
      </text>
      <text font-size="17" x="163.80608" y="924.3218" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_auckland_indie">
        auckland indie
      </text>
      <text font-size="21" x="154.99529" y="971.5863" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_baroque_pop">
        baroque pop
      </text>
      <text font-size="17" x="176.29518" y="944.9946" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nz_pop">
        nz pop
      </text>
      <text font-size="19" x="-332.279" y="5.586264" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hyperpop">
        hyperpop
      </text>
      <text font-size="16" x="-109.59761" y="-322.09192" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_girl_group">
        girl group
      </text>
      <text font-size="23" x="50.275085" y="-52.69077" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_florida_rap">
        florida rap
      </text>
      <text font-size="22" x="-204.1061" y="-101.410934" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vapor_trap">
        vapor trap
      </text>
      <text font-size="17" x="1083.3119" y="890.6085" fill="#00aee5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_atmospheric_dnb">
        atmospheric dnb
      </text>
      <text font-size="17" x="1003.1263" y="871.8464" fill="#00aee5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_glitchcore">
        glitchcore
      </text>
      <text font-size="19" x="-479.03656" y="-104.734924" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_deconstructed_club">
        deconstructed club
      </text>
      <text font-size="19" x="-478.60492" y="16.180061" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_proto-hyperpop">
        proto-hyperpop
      </text>
      <text font-size="17" x="-314.7328" y="-96.75543" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_social_media_pop">
        social media pop
      </text>
      <text font-size="16" x="-1364.7333" y="445.5704" fill="#008eb5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nightcore">
        nightcore
      </text>
      <text font-size="17" x="-123.03884" y="1154.3396" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_australian_alternative_rock">
        australian alternative rock
      </text>
      <text font-size="17" x="-107.581345" y="1257.7041" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_australian_rock">
        australian rock
      </text>
      <text font-size="24" x="-205.18217" y="1065.7865" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_wave_pop">
        new wave pop
      </text>
      <text font-size="17" x="-712.8995" y="-119.714676" fill="#ff004a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_motown">
        motown
      </text>
      <text font-size="21" x="-433.9679" y="1044.3188" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bubblegum_pop">
        bubblegum pop
      </text>
      <text font-size="27" x="-222.9733" y="987.00616" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_rock">
        classic rock
      </text>
      <text font-size="25" x="-464.21036" y="939.1909" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_folk_rock">
        folk rock
      </text>
      <text font-size="27" x="-527.79974" y="884.2244" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_mellow_gold">
        mellow gold
      </text>
      <text font-size="41" x="-166.81085" y="1040.9146" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rock">
        rock
      </text>
      <text font-size="19" x="-499.35028" y="1071.7139" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sunshine_pop">
        sunshine pop
      </text>
      <text font-size="19" x="-94.066635" y="947.6283" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_candy_pop">
        candy pop
      </text>
      <text font-size="22" x="-26.815493" y="879.82605" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_wave">
        new wave
      </text>
      <text font-size="36" x="-192.82678" y="908.72845" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_permanent_wave">
        permanent wave
      </text>
      <text font-size="19" x="-105.8814" y="974.4616" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_power_pop">
        power pop
      </text>
      <text font-size="21" x="-247.69516" y="930.89526" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_romantic">
        new romantic
      </text>
      <text font-size="23" x="-275.96048" y="949.75336" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_soft_rock">
        soft rock
      </text>
      <text font-size="21" x="-134.20978" y="877.6493" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sophisti-pop">
        sophisti-pop
      </text>
      <text font-size="21" x="-261.11365" y="779.22003" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_synthpop">
        synthpop
      </text>
      <text font-size="24" x="-447.48706" y="857.32776" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_album_rock">
        album rock
      </text>
      <text font-size="19" x="-545.21826" y="1009.1612" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_yacht_rock">
        yacht rock
      </text>
      <text font-size="20" x="-973.9853" y="770.76263" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_german_jazz">
        german jazz
      </text>
      <text font-size="20" x="-1011.0832" y="729.27466" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_piano">
        jazz piano
      </text>
      <text font-size="20" x="-908.7212" y="686.6761" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_modern_jazz_piano">
        modern jazz piano
      </text>
      <text font-size="20" x="-957.374" y="708.43414" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_straight-ahead_jazz">
        straight-ahead jazz
      </text>
      <text font-size="20" x="-944.5115" y="746.84863" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_stride">
        stride
      </text>
      <text font-size="17" x="-940.76544" y="370.468" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_pop">
        jazz pop
      </text>
      <text font-size="17" x="389.27127" y="682.03455" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_anti-folk">
        anti-folk
      </text>
      <text font-size="20" x="398.68857" y="698.5372" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_folk">
        indie folk
      </text>
      <text font-size="17" x="481.74457" y="737.18933" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_weird_america">
        new weird america
      </text>
      <text font-size="21" x="421.91406" y="719.9612" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_stomp_and_holler">
        stomp and holler
      </text>
      <text font-size="17" x="300.77283" y="317.78253" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_vintage_french_electronic">
        vintage french electronic
      </text>
      <text font-size="19" x="500.14124" y="1040.2004" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_accordion">
        accordion
      </text>
      <text font-size="19" x="491.21234" y="1081.6694" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_accordion">
        classical accordion
      </text>
      <text font-size="19" x="516.95197" y="1139.1066" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_italian_contemporary_jazz">
        italian contemporary jazz
      </text>
      <text font-size="19" x="459.3987" y="1118.827" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_accordion">
        jazz accordion
      </text>
      <text font-size="17" x="201.46059" y="499.42932" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chanson_paillarde">
        chanson paillarde
      </text>
      <text font-size="17" x="-739.07837" y="563.47284" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_vintage_schlager">
        vintage schlager
      </text>
      <text font-size="18" x="368.06622" y="-1191.088" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_icelandic_indie">
        icelandic indie
      </text>
      <text font-size="18" x="187.06409" y="-1204.0529" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_icelandic_pop">
        icelandic pop
      </text>
      <text font-size="18" x="275.84335" y="-1086.3494" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_icelandic_singer-songwriter">
        icelandic singer-songwriter
      </text>
      <text font-size="23" x="110.3339" y="-993.82764" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_trip_hop">
        trip hop
      </text>
      <text font-size="17" x="199.6952" y="1718.1531" fill="#009195"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_auteur-compositeur-interprete_quebecois">
        auteur-compositeur-interprete quebecois
      </text>
      <text font-size="17" x="164.01054" y="1681.6705" fill="#009195"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_folk">
        canadian folk
      </text>
      <text font-size="17" x="149.23218" y="1666.855" fill="#009195"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_indie_folk">
        canadian indie folk
      </text>
      <text font-size="17" x="180.73972" y="1699.3381" fill="#009195"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_quebecois">
        indie quebecois
      </text>
      <text font-size="16" x="-50.486332" y="-1628.3705" fill="#906e00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_belgian_pop">
        belgian pop
      </text>
      <text font-size="16" x="-65.966255" y="-1644.7084" fill="#906e00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_variete_francaise">
        variete francaise
      </text>
      <text font-size="16" x="31.050838" y="464.25668" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_musique_pour_enfants">
        musique pour enfants
      </text>
      <text font-size="18" x="-1507.2498" y="211.87354" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_movie_tunes">
        movie tunes
      </text>
      <text font-size="21" x="-309.42038" y="-216.60277" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_neo_soul">
        neo soul
      </text>
      <text font-size="17" x="-1557.6783" y="35.60955" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_disney">
        disney
      </text>
      <text font-size="19" x="-1495.0417" y="96.53882" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_show_tunes">
        show tunes
      </text>
      <text font-size="17" x="-587.68896" y="946.5676" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_uk_pop">
        classic uk pop
      </text>
      <text font-size="17" x="-1358.0913" y="133.308" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_tagalog_worship">
        tagalog worship
      </text>
      <text font-size="17" x="-1354.6034" y="25.333391" fill="#7b78fd"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_broadway">
        broadway
      </text>
      <text font-size="19" x="875.1531" y="592.03467" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_soundtrack">
        classic soundtrack
      </text>
      <text font-size="19" x="727.8949" y="645.6523" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_soundtrack">
        french soundtrack
      </text>
      <text font-size="17" x="887.6146" y="776.81445" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bow_pop">
        bow pop
      </text>
      <text font-size="19" x="749.1843" y="720.80383" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_compositional_ambient">
        compositional ambient
      </text>
      <text font-size="18" x="889.57837" y="-1378.3389" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_early_romantic_era">
        early romantic era
      </text>
      <text font-size="17" x="873.506" y="-1113.1218" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_polish_classical">
        polish classical
      </text>
      <text font-size="16" x="737.50476" y="289.41064" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_russian_classical_piano">
        russian classical piano
      </text>
      <text font-size="19" x="1122.624" y="-1240.6467" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_late_romantic_era">
        late romantic era
      </text>
      <text font-size="17" x="726.1939" y="168.85287" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_chinese_classical_performance">
        chinese classical performance
      </text>
      <text font-size="17" x="673.48303" y="187.6181" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_chinese_classical_piano">
        chinese classical piano
      </text>
      <text font-size="20" x="732.66016" y="221.14668" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_classical_performance">
        classical performance
      </text>
      <text font-size="16" x="-1741.6328" y="188.93338" fill="#0085cf"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_anime_piano">
        anime piano
      </text>
      <text font-size="17" x="-1791.9481" y="173.47241" fill="#0085cf"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_otacore">
        otacore
      </text>
      <text font-size="17" x="595.6927" y="307.41095" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_hungarian_classical_performance">
        hungarian classical performance
      </text>
      <text font-size="17" x="712.25024" y="325.4376" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_hungarian_classical_piano">
        hungarian classical piano
      </text>
      <text font-size="16" x="-1786.5354" y="205.9756" fill="#0085cf"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_japanese_instrumental">
        japanese instrumental
      </text>
      <text font-size="18" x="709.2458" y="541.39435" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_orchestral_soundtrack">
        orchestral soundtrack
      </text>
      <text font-size="19" x="721.6265" y="557.5486" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_soundtrack">
        soundtrack
      </text>
      <text font-size="16" x="-1816.7437" y="-378.15942" fill="#00a27d"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dream_smp">
        dream smp
      </text>
      <text font-size="16" x="-1832.2152" y="-363.47778" fill="#00a27d"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_minecraft">
        minecraft
      </text>
      <text font-size="16" x="-1243.2108" y="471.6275" fill="#a4394e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_gypsy_jazz">
        gypsy jazz
      </text>
      <text font-size="16" x="-1443.0886" y="-1244.8215" fill="#576914"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_baroque">
        baroque
      </text>
      <text font-size="16" x="-1284.7124" y="-1209.7527" fill="#576914"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_baroque">
        italian baroque
      </text>
      <text font-size="16" x="544.77924" y="1185.2532" fill="#00a743"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_mexican_classical">
        mexican classical
      </text>
      <text font-size="17" x="-480.10132" y="-1481.6396" fill="#989025"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_orchestra">
        orchestra
      </text>
      <text font-size="16" x="-621.432" y="-1483.3154" fill="#989025"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_symfonicky_orchestr">
        symfonicky orchestr
      </text>
      <text font-size="17" x="948.1111" y="-1396.6833" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_era">
        classical era
      </text>
      <text font-size="17" x="1021.67535" y="-1212.0544" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_german_opera">
        german opera
      </text>
      <text font-size="19" x="937.0445" y="-1444.5833" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_german_romanticism">
        german romanticism
      </text>
      <text font-size="17" x="1073.4519" y="-643.1261" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_violin">
        violin
      </text>
      <text font-size="16" x="-560.35455" y="-1647.1394" fill="#989025"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_british_orchestra">
        british orchestra
      </text>
      <text font-size="16" x="962.0558" y="147.83784" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_orchestral_performance">
        orchestral performance
      </text>
      <text font-size="18" x="1014.98535" y="-1299.9792" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_neoclassicism">
        neoclassicism
      </text>
      <text font-size="16" x="565.2933" y="239.72772" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_canadian_classical_piano">
        canadian classical piano
      </text>
      <text font-size="17" x="1144.9766" y="-1067.438" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_british_modern_classical">
        british modern classical
      </text>
      <text font-size="17" x="1315.2996" y="-1154.769" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_victorian_britain">
        victorian britain
      </text>
      <text font-size="17" x="1123.5219" y="-1455.2146" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_opera">
        italian opera
      </text>
      <text font-size="17" x="1077.3613" y="-1472.9265" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italian_romanticism">
        italian romanticism
      </text>
      <text font-size="17" x="1432.4078" y="-859.8535" fill="#737d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_soprano">
        classical soprano
      </text>
      <text font-size="17" x="1372.1056" y="-894.22235" fill="#737d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_opera">
        opera
      </text>
      <text font-size="17" x="1411.5542" y="-877.58704" fill="#737d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_operatic_pop">
        operatic pop
      </text>
      <text font-size="16" x="764.0991" y="239.73917" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_polish_classical_piano">
        polish classical piano
      </text>
      <text font-size="17" x="909.55066" y="-1340.1611" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_russian_romanticism">
        russian romanticism
      </text>
      <text font-size="17" x="1054.1573" y="-1401.8665" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_classical">
        ukrainian classical
      </text>
      <text font-size="17" x="559.5581" y="206.05396" fill="#9a681b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_caucasian_classical_piano">
        caucasian classical piano
      </text>
      <text font-size="20" x="368.48233" y="910.2837" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_rock">
        indie rock
      </text>
      <text font-size="20" x="293.11008" y="1087.0801" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_modern_rock">
        modern rock
      </text>
      <text font-size="19" x="262.88486" y="908.04724" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_quebec_indie">
        quebec indie
      </text>
      <text font-size="17" x="979.01807" y="-1416.355" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_french_romanticism">
        french romanticism
      </text>
      <text font-size="17" x="1148.0459" y="-1414.8376" fill="#ff0087"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_operetta">
        operetta
      </text>
      <text font-size="16" x="1439.1459" y="-908.58057" fill="#737d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classical_contralto">
        classical contralto
      </text>
      <text font-size="16" x="-1923.5604" y="240.6321" fill="#e50b64"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_arkansas_country">
        arkansas country
      </text>
      <text font-size="16" x="-1906.2002" y="223.3007" fill="#e50b64"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_outlaw_country">
        outlaw country
      </text>
      <text font-size="21" x="-312.0992" y="-362.69415" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_swedish_electropop">
        swedish electropop
      </text>
      <text font-size="19" x="-395.53354" y="-438.15607" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_swedish_indie_pop">
        swedish indie pop
      </text>
      <text font-size="21" x="-324.56723" y="-318.6933" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_swedish_pop">
        swedish pop
      </text>
      <text font-size="17" x="353.35693" y="-1694.8884" fill="#007b7a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_art_punk">
        art punk
      </text>
      <text font-size="17" x="243.91275" y="-1578.075" fill="#007b7a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_garage_pop">
        garage pop
      </text>
      <text font-size="17" x="412.8435" y="-1545.8652" fill="#007b7a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_noise_rock">
        noise rock
      </text>
      <text font-size="17" x="328.41864" y="-1499.1592" fill="#007b7a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_punk_blues">
        punk blues
      </text>
      <text font-size="17" x="359.9545" y="739.70337" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_eau_claire_indie">
        eau claire indie
      </text>
      <text font-size="23" x="-287.70264" y="1042.2748" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_melancholia">
        melancholia
      </text>
      <text font-size="19" x="-213.08916" y="-12.037059" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_detroit_hip_hop">
        detroit hip hop
      </text>
      <text font-size="21" x="-4.7654843" y="863.47845" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pop_rock">
        pop rock
      </text>
      <text font-size="18" x="-149.76886" y="796.9539" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_athens_indie">
        athens indie
      </text>
      <text font-size="19" x="-318.2584" y="1088.5482" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_folk">
        folk
      </text>
      <text font-size="19" x="-337.00537" y="967.0604" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_oxford_indie">
        oxford indie
      </text>
      <text font-size="19" x="-966.0027" y="-1043.7957" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_alternative_metal">
        alternative metal
      </text>
      <text font-size="19" x="-962.9499" y="-1167.9136" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nu_metal">
        nu metal
      </text>
      <text font-size="17" x="-903.6236" y="-1203.7478" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rap_metal">
        rap metal
      </text>
      <text font-size="18" x="-861.8106" y="-917.0731" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_metal">
        canadian metal
      </text>
      <text font-size="18" x="-844.864" y="-877.9195" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_rock">
        canadian rock
      </text>
      <text font-size="18" x="-897.9768" y="-895.61456" fill="#62982b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_post-grunge">
        post-grunge
      </text>
      <text font-size="17" x="-451.34897" y="762.6306" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_irish_rock">
        irish rock
      </text>
      <text font-size="17" x="-449.57153" y="746.7589" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_irish_singer-songwriter">
        irish singer-songwriter
      </text>
      <text font-size="19" x="-413.38577" y="705.6494" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_neo_mellow">
        neo mellow
      </text>
      <text font-size="17" x="-1211.3805" y="-902.3152" fill="#9e5c86"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_j-rock">
        j-rock
      </text>
      <text font-size="17" x="-1419.7878" y="-746.5952" fill="#9e5c86"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_japanese_alternative_rock">
        japanese alternative rock
      </text>
      <text font-size="17" x="-1294.0576" y="-688.5205" fill="#9e5c86"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_post-punk">
        japanese post-punk
      </text>
      <text font-size="17" x="-1142.3455" y="-800.8339" fill="#9e5c86"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_japanese_post-rock">
        japanese post-rock
      </text>
      <text font-size="24" x="-30.472855" y="-886.32874" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ambient">
        ambient
      </text>
      <text font-size="23" x="-178.07336" y="-1094.1449" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_intelligent_dance_music">
        intelligent dance music
      </text>
      <text font-size="18" x="164.35016" y="-1104.811" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_scottish_electronic">
        scottish electronic
      </text>
      <text font-size="21" x="-75.071815" y="-1142.3191" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_braindance">
        braindance
      </text>
      <text font-size="21" x="-24.266958" y="-1120.4408" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_uk_experimental_electronic">
        uk experimental electronic
      </text>
      <text font-size="21" x="32.152885" y="-1143.6678" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_deep_idm">
        deep idm
      </text>
      <text font-size="21" x="-39.981243" y="-1074.2604" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_drill_and_bass">
        drill and bass
      </text>
      <text font-size="21" x="-65.15406" y="-1163.5767" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_microhouse">
        microhouse
      </text>
      <text font-size="25" x="-166.01025" y="-955.85364" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ninja">
        ninja
      </text>
      <text font-size="24" x="7.7233777" y="-1054.2812" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_uk_bass">
        uk bass
      </text>
      <text font-size="17" x="1383.9601" y="371.5099" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_instrumental_math_rock">
        instrumental math rock
      </text>
      <text font-size="17" x="1419.6814" y="408.02783" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_southeast_asian_post-rock">
        southeast asian post-rock
      </text>
      <text font-size="21" x="1385.3318" y="42.334118" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_taiwan_indie">
        taiwan indie
      </text>
      <text font-size="17" x="1400.7822" y="389.222" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_taiwan_post-rock">
        taiwan post-rock
      </text>
      <text font-size="18" x="729.848" y="-584.99805" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_jazz">
        indie jazz
      </text>
      <text font-size="18" x="781.38074" y="-798.2241" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_jazz_boom_bap">
        jazz boom bap
      </text>
      <text font-size="19" x="-85.572624" y="-1192.148" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chillwave">
        chillwave
      </text>
      <text font-size="19" x="101.11823" y="-1164.8698" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nu_jazz">
        nu jazz
      </text>
      <text font-size="18" x="862.6067" y="-774.3524" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_abstract_beats">
        abstract beats
      </text>
      <text font-size="19" x="711.4715" y="-721.86755" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_wonky">
        wonky
      </text>
      <text font-size="17" x="-84.160095" y="-1425.5178" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_czech_electronic">
        czech electronic
      </text>
      <text font-size="17" x="-151.56355" y="-1272.8461" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_indie_game_soundtrack">
        indie game soundtrack
      </text>
      <text font-size="21" x="333.67465" y="-760.6894" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chillstep">
        chillstep
      </text>
      <text font-size="19" x="371.6734" y="-783.26514" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_melodic_dubstep">
        melodic dubstep
      </text>
      <text font-size="17" x="334.6848" y="-803.6221" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_substep">
        substep
      </text>
      <text font-size="23" x="-161.64247" y="-577.96533" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_electro_house">
        electro house
      </text>
      <text font-size="21" x="386.5702" y="-740.1581" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_future_bass">
        future bass
      </text>
      <text font-size="18" x="285.42087" y="-739.9314" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_gaming_edm">
        gaming edm
      </text>
      <text font-size="16" x="-302.02652" y="-396.46582" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chill_pop">
        chill pop
      </text>
      <text font-size="18" x="44.608257" y="-1034.2423" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_big_beat">
        big beat
      </text>
      <text font-size="17" x="793.44763" y="-532.4215" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_phonk">
        phonk
      </text>
      <text font-size="20" x="403.1764" y="-642.9818" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_portland_hip_hop">
        portland hip hop
      </text>
      <text font-size="23" x="339.71896" y="-69.86727" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_philly_rap">
        philly rap
      </text>
      <text font-size="23" x="92.130905" y="-163.08029" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_political_hip_hop">
        political hip hop
      </text>
      <text font-size="19" x="217.96547" y="88.880554" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rap_rock">
        rap rock
      </text>
      <text font-size="26" x="-94.1701" y="-74.72116" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hardcore_hip_hop">
        hardcore hip hop
      </text>
      <text font-size="28" x="346.48056" y="-12.211824" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_east_coast_hip_hop">
        east coast hip hop
      </text>
      <text font-size="22" x="455.60782" y="-42.459743" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_underground_rap">
        underground rap
      </text>
      <text font-size="19" x="233.57385" y="-67.69995" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_harlem_hip_hop">
        harlem hip hop
      </text>
      <text font-size="21" x="-160.15102" y="-292.98795" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hip_pop">
        hip pop
      </text>
      <text font-size="18" x="338.48572" y="10.606346" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_abstract_hip_hop">
        abstract hip hop
      </text>
      <text font-size="19" x="361.4082" y="148.9567" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_boom_bap">
        boom bap
      </text>
      <text font-size="16" x="439.01227" y="10.323585" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_horrorcore">
        horrorcore
      </text>
      <text font-size="18" x="314.84683" y="47.367397" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_wu_fam">
        wu fam
      </text>
      <text font-size="19" x="1337.3239" y="-571.0868" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_greek_pop">
        classic greek pop
      </text>
      <text font-size="19" x="1146.5576" y="-557.40546" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_greek_rock">
        classic greek rock
      </text>
      <text font-size="19" x="1239.9021" y="-656.5416" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_entehno">
        entehno
      </text>
      <text font-size="18" x="1257.6008" y="-545.0542" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_greek_pop">
        greek pop
      </text>
      <text font-size="21" x="1234.7427" y="-482.17703" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_laiko">
        laiko
      </text>
      <text font-size="17" x="1187.1587" y="-370.78302" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_greek_hip_hop">
        greek hip hop
      </text>
      <text font-size="20" x="1114.5863" y="-470.3233" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_greek_indie">
        greek indie
      </text>
      <text font-size="17" x="976.55566" y="-444.32605" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_greek_underground_rap">
        greek underground rap
      </text>
      <text font-size="19" x="1154.8951" y="-631.60913" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_neo_kyma">
        neo kyma
      </text>
      <text font-size="17" x="1364.8005" y="-457.97363" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bouzouki">
        bouzouki
      </text>
      <text font-size="17" x="1295.354" y="-391.12537" fill="#ae8000"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_rebetiko">
        rebetiko
      </text>
      <text font-size="25" x="130.66321" y="34.67771" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dark_trap">
        dark trap
      </text>
      <text font-size="17" x="220.35481" y="42.810005" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_korean_trap">
        korean trap
      </text>
      <text font-size="17" x="-1002.57806" y="-109.88872" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_asian_american_hip_hop">
        asian american hip hop
      </text>
      <text font-size="17" x="-896.9983" y="126.81537" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_battle_rap">
        battle rap
      </text>
      <text font-size="20" x="-1065.3649" y="90.44351" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_k-rap">
        k-rap
      </text>
      <text font-size="16" x="-1062.845" y="-82.772705" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_korean_underground_rap">
        korean underground rap
      </text>
      <text font-size="17" x="-1127.6951" y="-6.3809786" fill="#eb6500"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_korean_pop">
        korean pop
      </text>
      <text font-size="24" x="328.50018" y="-306.19708" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_miami_hip_hop">
        miami hip hop
      </text>
      <text font-size="17" x="117.18685" y="83.76021" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_orleans_rap">
        new orleans rap
      </text>
      <text font-size="17" x="113.17377" y="-75.549484" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_west_coast_rap">
        west coast rap
      </text>
      <text font-size="19" x="-50.87881" y="-276.74377" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_mississippi_hip_hop">
        mississippi hip hop
      </text>
      <text font-size="17" x="32.485382" y="-91.252914" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dfw_rap">
        dfw rap
      </text>
      <text font-size="20" x="86.97176" y="-217.03107" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_florida_drill">
        florida drill
      </text>
      <text font-size="20" x="77.86008" y="-112.258995" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_trap_latino">
        trap latino
      </text>
      <text font-size="16" x="-85.67977" y="-1.967866" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_slap_house">
        slap house
      </text>
      <text font-size="17" x="132.18309" y="276.73572" fill="#8c65b4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_cali_rap">
        cali rap
      </text>
      <text font-size="18" x="-43.046726" y="-486.1518" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_brostep">
        brostep
      </text>
      <text font-size="18" x="-55.621582" y="-717.8088" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_electronic_trap">
        electronic trap
      </text>
      <text font-size="18" x="58.92462" y="-514.7311" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_filthstep">
        filthstep
      </text>
      <text font-size="21" x="-143.07101" y="19.067703" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_canadian_hip_hop">
        canadian hip hop
      </text>
      <text font-size="16" x="-757.57446" y="1524.2435" fill="#e4637a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_jersey_hardcore">
        new jersey hardcore
      </text>
      <text font-size="16" x="-774.3631" y="1540.8466" fill="#e4637a"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_new_jersey_rap">
        new jersey rap
      </text>
      <text font-size="21" x="-169.2499" y="-191.9488" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_trap_soul">
        trap soul
      </text>
      <text font-size="17" x="108.967834" y="127.81471" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_scream_rap">
        scream rap
      </text>
      <text font-size="17" x="221.15189" y="10.005922" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_boston_hip_hop">
        boston hip hop
      </text>
      <text font-size="16" x="231.72414" y="-329.72055" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_lgbtq+_hip_hop">
        lgbtq+ hip hop
      </text>
      <text font-size="17" x="-999.13306" y="-1373.3983" fill="#be4ab5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_reggaeton">
        reggaeton
      </text>
      <text font-size="17" x="-1001.3549" y="-1286.7833" fill="#be4ab5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_reggaeton_colombiano">
        reggaeton colombiano
      </text>
      <text font-size="17" x="-869.56885" y="-1273.0625" fill="#be4ab5"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_urbano_latino">
        urbano latino
      </text>
      <text font-size="16" x="-936.6915" y="1295.8098" fill="#cc3d8b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_folk">
        ukrainian folk
      </text>
      <text font-size="17" x="-845.0487" y="1208.2197" fill="#cc3d8b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_ukrainian_indie">
        ukrainian indie
      </text>
      <text font-size="17" x="536.9313" y="1593.716" fill="#df393e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_judaica">
        judaica
      </text>
      <text font-size="17" x="574.00867" y="1486.748" fill="#df393e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_klezmer">
        klezmer
      </text>
      <text font-size="17" x="601.79315" y="1578.649" fill="#df393e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_russian_folk">
        russian folk
      </text>
      <text font-size="17" x="778.916" y="1556.7048" fill="#00983c"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_classic_russian_rock">
        classic russian rock
      </text>
      <text font-size="17" x="749.0584" y="1573.0319" fill="#00983c"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_russian_reggae">
        russian reggae
      </text>
      <text font-size="17" x="785.2863" y="1587.9109" fill="#00983c"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_russian_rock">
        russian rock
      </text>
      <text font-size="21" x="1395.0662" y="-223.66806" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_taiwan_pop">
        taiwan pop
      </text>
      <text font-size="18" x="1262.994" y="61.450943" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_taiwanese_indigenous">
        taiwanese indigenous
      </text>
      <text font-size="17" x="1436.7151" y="-158.5109" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_electropop">
        chinese electropop
      </text>
      <text font-size="18" x="1404.1885" y="8.84912" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_taiwan_hip_hop">
        taiwan hip hop
      </text>
      <text font-size="17" x="1533.3912" y="69.571556" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_minyao">
        chinese minyao
      </text>
      <text font-size="17" x="1490.6387" y="-65.34326" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_chinese_singer-songwriter">
        chinese singer-songwriter
      </text>
      <text font-size="16" x="1287.9156" y="-137.43521" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_classic_mandopop">
        classic mandopop
      </text>
      <text font-size="16" x="1345.8896" y="-121.40907" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_cantopop">
        cantopop
      </text>
      <text font-size="17" x="1240.9038" y="-62.843037" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chinese_viral_pop">
        chinese viral pop
      </text>
      <text font-size="17" x="1180.4929" y="-159.57822" fill="#0087aa"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_mainland_chinese_pop">
        mainland chinese pop
      </text>
      <text font-size="17" x="417.22397" y="757.16907" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_double_drumming">
        double drumming
      </text>
      <text font-size="17" x="-427.83496" y="808.07684" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_funk_metal">
        funk metal
      </text>
      <text font-size="17" x="-418.1305" y="822.90094" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_funk_rock">
        funk rock
      </text>
      <text font-size="17" x="-348.6973" y="837.6742" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dance_rock">
        dance rock
      </text>
      <text font-size="17" x="553.27014" y="671.3972" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_modern_folk_rock">
        modern folk rock
      </text>
      <text font-size="17" x="621.8662" y="559.11365" fill="#0092ff"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_uk_americana">
        uk americana
      </text>
      <text font-size="19" x="-344.05307" y="795.15247" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_beatlesque">
        beatlesque
      </text>
      <text font-size="19" x="-128.48721" y="1000.1007" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_britpop">
        britpop
      </text>
      <text font-size="19" x="-181.61626" y="958.53357" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_madchester">
        madchester
      </text>
      <text font-size="21" x="-40.86241" y="-596.90314" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_australian_dance">
        australian dance
      </text>
      <text font-size="18" x="-598.27344" y="-549.02496" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_australian_electropop">
        australian electropop
      </text>
      <text font-size="18" x="-685.80756" y="-532.5042" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_australian_indie">
        australian indie
      </text>
      <text font-size="19" x="-615.22974" y="-670.6295" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_downtempo">
        downtempo
      </text>
      <text font-size="17" x="-785.0616" y="-522.8054" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indietronica">
        indietronica
      </text>
      <text font-size="17" x="-829.0598" y="-636.15857" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dream_pop">
        dream pop
      </text>
      <text font-size="16" x="-232.0992" y="-434.69055" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_folk-pop">
        folk-pop
      </text>
      <text font-size="17" x="105.53322" y="-1074.2451" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_uk_alternative_pop">
        uk alternative pop
      </text>
      <text font-size="20" x="-290.62906" y="-75.7977" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_houston_rap">
        houston rap
      </text>
      <text font-size="20" x="115.19851" y="-703.43616" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_aussietronica">
        aussietronica
      </text>
      <text font-size="19" x="41.173096" y="-720.3943" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_nu_disco">
        nu disco
      </text>
      <text font-size="19" x="-216.15814" y="-909.30493" fill="#709d00"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_future_garage">
        future garage
      </text>
      <text font-size="17" x="359.71924" y="-961.4419" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_deep_liquid">
        deep liquid
      </text>
      <text font-size="17" x="376.4604" y="-979.1488" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_drum_and_bass">
        drum and bass
      </text>
      <text font-size="17" x="340.89554" y="-942.5909" fill="#257949"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_liquid_funk">
        liquid funk
      </text>
      <text font-size="17" x="-476.72403" y="-199.97913" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_funk">
        funk
      </text>
      <text font-size="22" x="-227.7317" y="1138.6445" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_british_invasion">
        british invasion
      </text>
      <text font-size="22" x="-366.91394" y="1212.7494" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_glam_rock">
        glam rock
      </text>
      <text font-size="22" x="-405.9473" y="1159.2219" fill="#ff3403"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_protopunk">
        protopunk
      </text>
      <text font-size="20" x="-473.87985" y="-694.9429" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_eurodance">
        eurodance
      </text>
      <text font-size="19" x="-564.24615" y="-630.7163" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_europop">
        europop
      </text>
      <text font-size="19" x="-725.4745" y="-710.3313" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_bubblegum_dance">
        bubblegum dance
      </text>
      <text font-size="18" x="-703.36145" y="-785.7368" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_german_techno">
        german techno
      </text>
      <text font-size="20" x="-595.18713" y="-841.6145" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_hands_up">
        hands up
      </text>
      <text font-size="19" x="-496.7508" y="-757.0078" fill="#00885b"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_italo_dance">
        italo dance
      </text>
      <text font-size="19" x="-483.81366" y="-311.00458" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_metropopolis">
        metropopolis
      </text>
      <text font-size="19" x="-505.0249" y="-515.60626" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_swedish_synthpop">
        swedish synthpop
      </text>
      <text font-size="20" x="-194.49385" y="-696.9601" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_big_room">
        big room
      </text>
      <text font-size="22" x="-79.27315" y="-368.9969" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_house">
        house
      </text>
      <text font-size="20" x="-181.2294" y="-477.71436" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_progressive_electro_house">
        progressive electro house
      </text>
      <text font-size="20" x="-262.18234" y="-626.63617" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_progressive_house">
        progressive house
      </text>
      <text font-size="19" x="-130.8004" y="-361.9652" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_grime">
        grime
      </text>
      <text font-size="16" x="-388.2543" y="-383.73798" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_indie_poptimism">
        indie poptimism
      </text>
      <text font-size="16" x="1857.5638" y="-783.8855" fill="#00731e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dutch_pop">
        dutch pop
      </text>
      <text font-size="16" x="1842.0559" y="-800.22864" fill="#00731e"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_dutch_rock">
        dutch rock
      </text>
      <text font-size="17" x="46.75328" y="213.53354" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans"
        class="id_deep_underground_hip_hop">
        deep underground hip hop
      </text>
      <text font-size="25" x="-74.2576" y="-110.54867" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_queens_hip_hop">
        queens hip hop
      </text>
      <text font-size="20" x="113.65767" y="-359.67426" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sudanese_hip_hop">
        sudanese hip hop
      </text>
      <text font-size="20" x="-20.608856" y="-301.6853" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_sudanese_pop">
        sudanese pop
      </text>
      <text font-size="21" x="-121.94629" y="-167.51741" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_chicago_rap">
        chicago rap
      </text>
      <text font-size="16" x="208.21457" y="-16.845682" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_soul_flow">
        soul flow
      </text>
      <text font-size="18" x="-114.33444" y="115.915115" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_toronto_rap">
        toronto rap
      </text>
      <text font-size="19" x="-403.5908" y="-120.670105" fill="#00aeb6"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_afrofuturism">
        afrofuturism
      </text>
      <text font-size="19" x="157.03069" y="-32.646973" fill="#d756f4"
        style="text-anchor: middle; dominant-baseline: central;" font-family="Gill Sans" class="id_pittsburgh_rap">
        pittsburgh rap
      </text>
    </g>
  </svg>

</div>