import { Component, OnInit } from '@angular/core';
import songs from '../../assets/data/top-25-tracks.json';
import allSongs from '../../assets/data/all_songs_by_genre.json';
import * as $ from 'jquery';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { MusicViewerComponent } from '../music-viewer/music-viewer.component';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.css'],
})
export class MusicComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    jQuery(function () {
      // animate in
      $('footer').fadeIn(400);
      $('.top-section').fadeIn(400);
      $('.about-music').fadeIn(400);
      $('.da-card').fadeIn(400);
      $('.music-carousel').fadeIn(400);

      // add navigator
      $('#tg-btn').on('click', () => {
        $('#ts').hide();
        $('#ts-btn').removeClass('disabled');
        $('#tg').show();
        $('#tg-btn').addClass('disabled');
      });
      $('#ts-btn').on('click', () => {
        $('#tg').hide();
        $('#tg-btn').removeClass('disabled');
        $('#ts').show();
        $('#ts-btn').addClass('disabled');
      });
    });

    this.addSongList();
    this.addGenreLinks();
  }

  addSongList() {
    var audioSrc = document.getElementById('spotifyplayer') as HTMLAudioElement;
    var orderedListElement = document.createElement('ol');

    var indic = document.createElement('a');
    indic.innerHTML = '♪ ';
    indic.style.color = 'purple';

    for (var i = 0; i < Math.min(songs.length, 30); ++i) {
      var listItem = document.createElement('li');

      listItem.append(this.createSongElement(songs[i], audioSrc, indic));
      listItem.append(this.createArtistLinks(songs[i]));

      orderedListElement.appendChild(listItem);
    }
    $('#ts').append(orderedListElement); // Note here
  }

  addGenreLinks() {
    var audioSrc = document.getElementById('spotifyplayer') as HTMLAudioElement;
    var container = document.getElementById('svg-container')
    var viewport = document.querySelector('#viewport');
    var nodes = viewport.children;

    var indic = document.createElement('a');
    indic.innerHTML = '♪ ';
    indic.style.color = 'black';

    for (var i = nodes.length - 1; i >= 0; i--) {
      var child = nodes[i] as SVGTextContentElement;
      var genre = child.innerHTML.trim()

      if (genre in allSongs) {
        child.onclick = this.generatePlayFunction(indic, allSongs[genre][0], audioSrc, this.genreListIndicate)
        // child.onclick = (e) => {
        //   var songItem = document.createElement('a')
        //   songItem.classList.add("dropdown-item")
        //   songItem.href = '#'
        //   songItem.innerText = "Action"
        //   var dropdown = document.createElement('div') 
        //   dropdown.classList.add("dropdown-menu");
        //   dropdown.append(songItem)
        //   var div = document.createElement('div')
        //   var left  = e.clientX  + "px";
        //   var top  = e.clientY  + "px";
        //   div.style.left = left;
        //   div.style.top = top;
        //   child.setAttribute("data-toggle", "dropdown")
        //   child.setAttribute("aria-haspopup", "true") 
        //   child.setAttribute("aria-expanded", "false")
        //   // $("#"+divid).toggle();
        //   div.innerHTML = "hi"
        //   div.style.position = "fixed"
        //   div.append(dropdown)
        //   container.append(div)
        // }
      } else {
        child.style.fill = "#EEEEEE"
        child.style.cursor = "auto"
      }
    }
    // container.innerHTML += ""
  }

  createSongElement(song, audioSrc, indic) {
    var songElement = document.createElement('a');

    songElement.style.color = "grey"
    songElement.innerHTML += song.name;
    songElement.href = '#';
    songElement.onclick = this.generatePlayFunction(indic, song, audioSrc, this.songListIndicate);
    return songElement;
  }

  createArtistLinks(song) {
    var artists = document.createElement('span');
    artists.innerHTML = ' - ';

    for (var j = 0; j < song.artists.length; ++j) {
      var artist = document.createElement('a');
      artist.innerHTML = song.artists[j];
      artist.href = 'https://en.wikipedia.org/wiki/' + song.artists[j];
      artist.target = '_blank';
      artist.style.color = 'black';
      artists.append(artist);
      if (j != song.artists.length - 1) artists.innerHTML += ', ';
    }

    return artists;
  }

  generatePlayFunction(indic, song, audioSrc, indicatorFunction) {
    // generate on click function
    return function (event) {
      event.preventDefault();
      if (audioSrc.paused || audioSrc.src != song.url) {
        if (audioSrc.src != song.url) audioSrc.src = song.url;
        console.log(song.url)
        console.log(song.name)
        audioSrc.play();
        indicatorFunction(true, indic, this, song)
      } else {
        audioSrc.pause();
        indicatorFunction(false, indic, this, song)
      }
    };
  }

  songListIndicate(playing, indic, songElement, song) {
    var color = "purple";
    if (song.genres.length > 0) {
      var classId = "id_" + song.genres[0].trim().replaceAll(" ", "_")
      var matchingGenreElement = document.getElementsByClassName(classId)[0]
      if (matchingGenreElement) 
        color = matchingGenreElement.getAttribute("fill");
    }

    indic.style.color = color;
    songElement.style.color = color;

    if (playing) {
        indic.hidden = false;
        songElement.prepend(indic);
    } else {
        indic.hidden = true;
    }
  }

  genreListIndicate(playing, indic, songElement, song) {
    // add underlines to all genres which would provide the same song
    for (let i = 0; i < song.genres.length; i++) {
      var relatedGenreClass = "id_" + song.genres[i].trim().replaceAll(" ", "_")
      var text = document.getElementsByClassName(relatedGenreClass)[0] as SVGTextContentElement
      if (allSongs[song.genres[i]][0].name == song.name) {
        if (playing) {
          text.style.fontStyle = "italic"
          function adjustColor(color, amount) {
              return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
          }
          text.style.fill = adjustColor(text.getAttribute("fill"), -140);
        }
      }
    }
  }

  // genreListIndicate(playing, indic, songElement, song) {
  //   var allGenreElements = document.getElementById("viewport").children
  //   // remove all underlines
  //   for (let i = 0; i < allGenreElements.length; i++) {
  //     (allGenreElements[i] as HTMLElement).style.fontStyle = "normal";
  //   }
  //   // add underlines to all genres which would provide the same song
  //   for (let i = 0; i < song.genres.length; i++) {
  //     var relatedGenreClass = "id_" + song.genres[i].trim().replaceAll(" ", "_")
  //     var text = document.getElementsByClassName(relatedGenreClass)[0] as SVGTextContentElement
  //     if (allSongs[song.genres[i]][0].name == song.name) {
  //       if (playing) {
  //         text.style.fontStyle = "italic"
  //         function adjustColor(color, amount) {
  //             return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  //         }
  //         text.style.fill = adjustColor(text.getAttribute("fill"), -140);
  //       }
  //     }
  //   }
  // }


}
