import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-digital-art',
  templateUrl: './digital-art.component.html',
  styleUrls: ['./digital-art.component.css']
})
export class DigitalArtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function() {
      $("footer").fadeIn(400); 
      $(".top-section").fadeIn(400); 
      $(".about-art").fadeIn(400); 
      $(".da-card").fadeIn(400); 
      $(".art-carousel").fadeIn(400);
    });
  }

}
