import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ResumeComponent } from './resume/resume.component';
import { SoftwareComponent } from './software/software.component';
import { ResearchComponent } from './research/research.component';
import { DigitalArtComponent } from './digital-art/digital-art.component';
import { MusicComponent } from './music/music.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [

  { path: '', redirectTo: '/resume', pathMatch: 'full'},
  { path: 'resume', component: ResumeComponent},
  { path: 'software-unity-game-1', component: SoftwareComponent},
  { path: 'software-arbitrage-2', component: SoftwareComponent},
  { path: 'software-web-crawler-3', component: SoftwareComponent},
  { path: 'software-javafx-game-4', component: SoftwareComponent},
  { path: 'software-flask-app-5', component: SoftwareComponent},
  { path: 'research', component: ResearchComponent, children: [
    { path: "**", component: ResearchComponent}
  ]},
  { path: 'interests', children: [
    { path: 'artwork', component: DigitalArtComponent},
    { path: 'music', component: MusicComponent}
  ]},
  { path: 'about', component: AboutComponent},
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
