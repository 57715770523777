<div class="top-section" style="display: none">
    <div class="rounded-box about-music" style="display: none">
        <h1>Music</h1>
        <p>
            I love to listen, play and make music - but when people ask me what I listen to, I can never give a
            clear answer. So, I did some processing of my Spotify listening history to help answer this question,
            Below is a list of my all-time favourite songs alongside an interactive explorer of every genre that appears
            in my playlists.
        </p>
        <div class="display: none"><audio controls="controls" id=spotifyplayer style="display: none"></audio></div>
        <div class="mid-flex">
            <div style="padding-right: 5%">
                <div id="ts">
                    <h3>All-time Top 40 Songs</h3>
                </div>
            </div>
            <div class="card mb-3 visualisation-card">
                <div class="card-header">Musical Taste Visualisation</div>
                <div style="position:relative">
                    <app-music-viewer></app-music-viewer>
                    <div style="padding:2%">
                        <h5 class="card-title">Genre mapping of all my playlists</h5>
                        <p class="card-text">An interactive visualisation of the variety of genres belonging to my
                            spotify playlists. Click on one of the coloured titles to play a song I like from that
                            genre. Scroll or drag to move around the graph.</p>
                    </div>
                </div>
            </div>
        </div>
        <p>
            I also play the piano, and wrote an original piece which you can listen to below.
        </p>
    </div>
</div>

<!-- music carousel -->
<div style="display: none;" class="music-carousel rounded-box">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <!-- <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol> -->
        <div class="carousel-inner">
            <div class="carousel-item active">
                <iframe class="d-block w-100 video" src="https://www.youtube.com/embed/k3scMGf94BQ"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <!-- <div class="carousel-item"> </div> -->
        </div>
        <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a> -->
    </div>
</div>