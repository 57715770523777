<div class="heading rounded-box">
    <h1>My Research Portfolio</h1>
    <p>
    Throughout my undergraduate degree I have undertaken various research assignments and projects. Although I'm unable to publish some of these, I have curated some reports to display my of research and experiment experience as well as my typesetting skills. Click on the buttons below to navigate them!
    </p>
    <!-- transition bar -->
    <div>
    <nav aria-label="Portfolio Navigation" >
        <ul class="pagination justify-content-center" style="cursor: pointer;">
            <li class="page-item" *ngFor="let entry of pageEntries; let i = index">
                <a class="page-link" href="research/{{entry[0]}}">{{i+1}}</a>
            </li> 
        </ul>
    </nav>
    </div>
</div>


<!-- portfolio pages -->
<div id="1" class="portfolio rounded-box">
    <div class="description">
        <h2>{{page?.title}}</h2>
        <p> {{page?.description}} </p>
    </div>
    <div class="pdf">
        <app-pdf-view url={{page?.url}} filename={{page?.filename}} embedMode="IN_LINE" ></app-pdf-view>
    </div>
</div>