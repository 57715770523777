<!-- about section -->
<div class="top-section" style="display: none">
    <!-- about art section -->
    <div class="rounded-box about-art" style="display: none">
        <h1>Digital Art</h1> 
        <p>
            I enjoy making logos and wallpapers using Adobe Photoshop. These mainly
            consist of cutouts of characters from video games and anime series in
            some sort of characteristic environment, designed using my selection and
            combination of textures, brushes, colour maps, blending modes, etc.
            These often take a week or so to complete. My screen name is Kothanos,
            as seen on my deviantArt profile. I've been uploading my artworks on
            deviantArt since 2014, and currently have 7000+ pageviews and 27
            faithful "watchers". 
        </p>
        <p>
            My complete gallery can be found on my on my deviantArt profile
            consisting of artworks from as old as 2014. At around this time, 
            when I began creating digital art, I was involved in a competition
            for designing a poster for an Australian A-league soccer match 
            between Sydney FC and a local premier league team. I designed the 
            winning poster for which I was rewarded with meeting the players
            of the teams and performing a ceremonial kick-off for the game.
            This has been one of the highlights of my digital art design hobby.
        </p>
        <p>
            Throughout high school I designed logos and sold them as a
            freelancer digital artist. Many logos, however, I designed for
            free for friends online profiles or entreprenuerial projects. In
            the more recent past, I have been selling designs on Redbubble for
            a commission, consisting of artworks similar to those featured 
            below. These so far have been printed over T-shirts, iPhone cases, 
            posters and much more.
        </p>
    </div>

    <!-- deviantArt Card-->
    <div class="rounded-box da-card">
        <div class="card" style="min-width: 14em; max-width: 30%;">
            <img src="assets/img/digital-art/da_profile_picture.png" class="card-img-top" alt="DeviantArt Icon">
            <div class="card-body">
            <h5 class="card-title">Kothanos' Gallery</h5>
            <p class="card-text">My full gallery is on my deviantArt page!</p>
            <a href="https://www.deviantart.com/kothanos" class="btn btn-primary">Check it out!</a>
            </div>
        </div>
    </div>

</div>

<!-- art carousel -->
<div style="display: none;" class="art-carousel rounded-box">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
        <img class="d-block w-100" 
            src="assets/img/digital-art/alexander-the-great.jpg" alt="Alexander the Great">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100"
        src="assets/img/digital-art/raiden.jpg" alt="Raiden, Metal Gear Solid">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100"
        src="assets/img/digital-art/sub-zero.jpg" alt="Sub-zero, Mortal Kombat">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100" 
        src="assets/img/digital-art/snake.jpg" alt="Snake, Metal Gear Solid">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100" 
        src="assets/img/digital-art/scorpion.jpg" alt="Scorpion, Mortal Kombat">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100" 
        src="assets/img/digital-art/yagami.jpg" alt="Yagami Light, Death Note">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100" 
        src="assets/img/digital-art/caius.png" alt="Caius Ballad, Final Fantasy">
        </div>
        <div class="carousel-item">
        <img class="d-block w-100" 
        src="assets/img/digital-art/ryuzaki.jpg" alt="Ryuzaki, Death Note">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" 
        role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" 
        role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    </div>
</div>