<nav class="navbar navbar-expand-lg navbar-light bg-white">

  <span class="navbar-brand mb-0">Dean Poulos</span>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse order-3" id="navbarNavDropdown">
    <ul class="navbar-nav">

      <li id="resume" class="nav-item">
        <a class="nav-link" routerLink="resume" (click)="highlightNewBtn('resume')">
          My Resume
        </a>
      </li>

      <li id="software" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Software Portfolio
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="software-unity-game-1" fragment="1">Home</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="software-unity-game-1" fragment="1">
            C# RPG Game
          </a>
          <a class="dropdown-item" routerLink="software-arbitrage-2" fragment="2">
            Currency Arbitrage Bot
          </a>
          <a class="dropdown-item" routerLink="software-web-crawler-3" fragment="3">
            Trading Recommendation Web-Scanner
          </a>
          <a class="dropdown-item" routerLink="software-javafx-game-4" fragment="4">
            Java FXML Dungeon Game
          </a>
          <a class="dropdown-item" routerLink="software-flask-app-5" fragment="5">
            Flask Interactive Web-Application
          </a>
        </div>
      </li>

      <li id="research" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"> Research Portfolio </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="research/{{page[0]}}" (click)="highlightNewBtn('research')" href="#"
            *ngFor="let page of myPages">{{page[1].title}}</a>
        </div>
      </li>

      <li id="interests" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">My Interests</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="interests/music" (click)="highlightNewBtn('interests')"> Music </a>
          <a class="dropdown-item" routerLink="interests/artwork" (click)="highlightNewBtn('interests')"> Digital Art </a>
        </div>
      </li>

      <li id="about" class="nav-item">
        <a class="nav-link" routerLink="about" (click)="highlightNewBtn('about')">
          About Me
        </a>
      </li>

    </ul>
  </div>

</nav>