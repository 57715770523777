import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import pages from '../../assets/pages/research.json';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css'],
})
export class ResearchComponent implements OnInit {
  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this.setPage();
      // this.reloadPDFViewer();
    });
  }

  page = { title: null, url: null, filename: null, description: null };
  pageEntries = Object.entries(pages);
  showPDF = false;

  ngOnInit(): void {
    $(document).ready(function () {
      $('.heading').fadeIn(400);
      $('.portfolio').fadeIn(400);
    });
    this.setPage();
  }

  setPage() {
    var page: string = window.location.pathname.split('/').pop();
    this.page = pages[page];
  }

  reloadPDFViewer() {
    this.showPDF = false;
    this.showPDF = true;
  }
}
