import { Component, OnInit } from '@angular/core';
import researchPages from "../../assets/pages/research.json"

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

  constructor() { }

  pages = [
    "resume",
    "software",
    "research",
    "interests",
    "about"
  ];

  myPages = Object.entries(researchPages)

  ngOnInit(): void {
    this.highlightCurrBtn();
  }

  highlightNewBtn(myPage) {
    // for every page 
    for (var page in this.pages) {
      // save element with the page name as id
      var pageBtnList = document.getElementById(this.pages[page]);
      // if the elemnt is not null
      if (pageBtnList != null) {
        if (this.pages[page] == myPage) {
          // activate button if on this page
          pageBtnList.classList.add("active")
        } else {
          pageBtnList.classList.remove("active")
        }
      }
    }
  }

  highlightCurrBtn() {
    // for every page 
    for (var page in this.pages) {
      // save element with the page name as id
      var pageBtnList = document.getElementById(this.pages[page]);
      // if the elemnt is not null
      if (pageBtnList != null) {
        // if the elemnt is not null
        if (window.location.href.indexOf(this.pages[page]) !== -1) {
          // activate button if on this page
          pageBtnList.classList.add("active")
        } else {
          pageBtnList.classList.remove("active")
        }
      }
    }
  }

}
