import { Component, OnInit } from '@angular/core';
import { strict } from 'assert';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.css']
})

export class SoftwareComponent implements OnInit {

    constructor () { }

    fadeTo(hash) {
        $(".portfolio").fadeOut(400);
        $(".page-item").addClass("disabled");
        toggleLeftArrow(true); toggleRightArrow(true);
        $(".portfolio").promise().done(function () {
            $('#' + hash).fadeIn(400);
            $('#' + hash).css({"margin-left":"10%"});
            $(".page-item").removeClass("disabled");
        });
        updateNavbar(hash);
        setArrows(Number(hash));
    }

    ngOnInit(): void {
    
        $(document).ready(function(){

            /* Load Page */
            updateURL(window.location.hash); // cleanup URL

            //  - apply only valid anchor, default otherwise
            var hash = "1";
            var fade = true;
            var smooth = false;
            if(window.location.hash) {
                hash = window.location.hash.substring(1); 
                if (Number(hash) < 1 || Number(hash) > 5) {
                hash = "1";
                updateURL(hash);
                } else {
                fade = false;
                }
            }
            updateNavbar(hash);
            clearURL();

            toggleLeftArrow(true); toggleRightArrow(true); // disable clicks

            //  - fade into center upon load
            $('#' + hash).css({"margin-left":"10%"});
            if (fade) {
            $('#' + hash).fadeIn(400);
            $(".heading").fadeIn(400);
            } else {
            $('#' + hash).show();
            $(".heading").show();
            $('html,body').animate({scrollTop:$("body").offset().top}, 0);
            $('html,body').animate({scrollTop:$(".portfolio-wrapper").offset().top}, 
                                    {duration: 500, easing: "easeOutQuad"});
            }
            $('#' + hash).promise().done(function() {
            setArrows(hash);
            });


            /* Transitions */
            //  - transition backward
            $(".btn").click(function () {

                hash = activePage();
                
                // direction
                var direction = Number($(this).attr("value"));

                // disable buttons
                toggleLeftArrow(true); toggleRightArrow(true);
                $(".page-item").addClass("disabled");

                slideOut('#' + hash, direction);
                var newHash = Number(hash) + direction;
                slideIn('#' + newHash.toString(), direction); 
                updateNavbar(newHash);

                updateURL(newHash.toString());
                clearURL();

                // enable buttons
                $('#' + newHash).promise().done(function(){ 
                    $('#' + hash).css({"margin-left":"120%"});
                    $('#' + hash).hide();
                    if (newHash < 5) toggleRightArrow(false);
                    if (newHash > 1) toggleLeftArrow(false);
                    $(".page-item").removeClass("disabled");
                    hash = newHash.toString();
                });

            });

            $(".active").click(function() {
                $(".page-link").click(function() {
                    event.preventDefault(); 
                    $('html,body').animate({scrollTop:$(".portfolio-wrapper").offset().top}, 500);
                });
            });
        });

    }

}

function slideIn(divID, direction) {

    // start on left or right
    var start = (10 + (direction * 110)).toString() + "%";
    $(divID).css({"margin-left":start});
    // animate in
    $(divID).animate( 
        { marginLeft: "10%" }, 
        {duration: 750, easing: "easeOutQuad", queue: false}
    );
    $(divID).fadeIn(750);
}

function slideOut(divID, direction) {
    
    // goto left or right
    var end = (10 - (direction * 110)).toString() + "%";
    $(divID).animate(
        { marginLeft : end}, 
        {duration: 1000, easing: "easeOutQuad", queue: false}
    );
}

function toggleRightArrow(bool) {
    if (bool) document.getElementById("btn-right").classList.add('disabled');
    else document.getElementById("btn-right").classList.remove('disabled');
}

function toggleLeftArrow(bool) {
    if (bool) document.getElementById("btn-left").classList.add('disabled');
    else document.getElementById("btn-left").classList.remove('disabled');
}

function updateNavbar(hash) {
    document.querySelectorAll(".page-item").forEach(function(node) {
        node.classList.remove("active");
    });
    document.getElementById('l' + hash).classList.add("active");
}

function setArrows(hash) {
    if (hash < 5) toggleRightArrow(false);
    if (hash > 1) toggleLeftArrow(false); 
}

function activePage() {
    var number = -1;
    document.querySelectorAll(".page-item").forEach(function(node) {
        if (node.classList.contains("active")) {
            number = Number(node.id[1]);
        }
    });

    return number.toString();
}

window.addEventListener('popstate', function (event) {
	// Log the state data to the console
	console.log(event.state);
});

function updateURL(anchor) {
    //history.pushState(null, null, "software.html" + anchor);
}

function clearURL() {
    //history.pushState(null, null, "software.html");
}